import React from 'react';
import styled from 'styled-components';
import InfoWithIcon from '../atoms/InfoWithIcon';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderLargeBlue,
  InfoHeaderSmallGray,
  infoHeadersTransformations,
  InfoHeaderBlack,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';
import CashFlowIcon from '../images/CashFlowIcon';
import KeyFinding4Image from '../images/KeyFinding4Image';
import DesktopInfoImageWrapper from './DesktopInfoImageWrapper';
import MobileInfoImageWrapper from './MobileInfoImageWrapper';

const StyledInfoWithIcon = styled(InfoWithIcon)`
  padding-top: 2rem;

  @media ${breakpoints.lg} {
    margin-left: 3rem;
    padding-top: 17rem;
    width: 35.625rem;
  }

  @media ${breakpoints.xxl} {
    padding-top: 24rem;
  }
`;

const Header = styled.header`
  & > ${InfoHeaderSmallGray} {
    margin-left: 0.5rem;
  }

  & > ${InfoHeaderBlack} {
    display: block;
    margin-top: 0.5rem;
  }

  @media ${breakpoints.lg} {
    display: grid;
    grid-template-areas: 'a b' 'a c';
    grid-template-rows: 1.6fr 1fr;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 1rem;

    & > ${InfoHeaderLargeBlue} {
      grid-area: a;
      margin-left: -3rem;
    }

    & > ${InfoHeaderSmallGray} {
      grid-area: b;
      margin-bottom: 0.5rem;
      margin-left: 0;
    }

    & > ${InfoHeaderBlack} {
      grid-area: c;
    }
  }
`;

const StyledMobileInfoImageWrapper = styled(MobileInfoImageWrapper)`
  top: -6rem;
  left: 0;
  width: 16rem;
  z-index: -1;

  @media ${breakpoints.md} {
    width: 20rem;
    left: auto;
    right: 0;
  }

  & > .gatsby-image-wrapper {
    left: -2rem;

    @media ${breakpoints.md} {
      left: auto;
      right: 0;
    }
  }
`;

const Info7 = () => (
  <>
    <DesktopInfoImageWrapper>
      <KeyFinding4Image />
    </DesktopInfoImageWrapper>

    <StyledMobileInfoImageWrapper>
      <KeyFinding4Image />
    </StyledMobileInfoImageWrapper>

    <StyledInfoWithIcon
      iconOffset={-50}
      icon={<CashFlowIcon />}
      header={
        <Header>
          <FormattedMessage
            id="home.informationSection.items.6.header"
            values={infoHeadersTransformations}
          />
        </Header>
      }
    >
      <FormattedHTMLMessage id="home.informationSection.items.6.text" />
    </StyledInfoWithIcon>
  </>
);

export default Info7;
