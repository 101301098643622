import React from 'react';
import ideaIcon from '../../images/idea-icon.svg';

const IdeaIcon = props => {
  return (
    <img src={ideaIcon} alt="IdeaIcon" {...props} />
  );
};

export default IdeaIcon;
