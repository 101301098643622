import React from 'react';
import wishes7 from '../../images/wishes-7.svg';

const Wishes7Shape = props => {
  return (
    <img src={wishes7} alt="Wishes7Shape" {...props} />
  );
};

export default Wishes7Shape;
