import React from 'react';
import wishes8 from '../../images/wishes-8.svg';

const Wishes8Shape = props => {
  return (
    <img src={wishes8} alt="Wishes8Shape" {...props} />
  );
};

export default Wishes8Shape;
