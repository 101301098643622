import React from 'react';
import connectionsIcon from '../../images/connections-icon.svg';

const ConnectionsIcon = props => {
  return (
    <img src={connectionsIcon} alt="ConnectionsIcon" {...props} />
  );
};

export default ConnectionsIcon;
