import React from 'react';
import styled from 'styled-components';
import InfoCircle from '../atoms/InfoCircle';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderBlack,
  InfoHeaderLargeBlue,
  InfoHeaderSmallGray,
  infoHeadersTransformations,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';

const StyledInfoCircle = styled(InfoCircle)`
  @media ${breakpoints.lg} {
    margin-top: -1rem;
  }

  @media ${breakpoints.xxl} {
    margin-top: 6rem;
  }
`;

const Header = styled.header`
  display: grid;
  grid-template-areas: 'a b' 'a c';
  align-items: flex-start;
  justify-content: center;
  grid-column-gap: 0.75rem;

  & > ${InfoHeaderLargeBlue} {
    grid-area: a;
    line-height: 0.9;
  }

  & > ${InfoHeaderSmallGray} {
    grid-area: b;
    text-align: left;
    align-self: center;
  }

  & > ${InfoHeaderBlack} {
    grid-area: c;
    align-self: flex-end;
  }
`;

const Info10 = () => (
  <StyledInfoCircle
    header={
      <Header>
        <FormattedMessage
          id="home.informationSection.items.9.header"
          values={infoHeadersTransformations}
        />
      </Header>
    }
  >
    <FormattedHTMLMessage id="home.informationSection.items.9.text" />
  </StyledInfoCircle>
);

export default Info10;
