import React from 'react';
import wishes0 from '../../images/wishes-0.svg';

const Wishes0Shape = props => {
  return (
    <img src={wishes0} alt="Wishes0Shape" {...props} />
  );
};

export default Wishes0Shape;
