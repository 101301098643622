import PropTypes from 'prop-types';
import React from 'react';
import { FaPlus } from 'react-icons/all';
import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import { acceleratedEasing, cardStyles } from '../../utils/styles';
import { PKOH4 } from '../atoms/Headers';
import IconCircle from '../atoms/IconCircle';
import DialogBox from '../molecules/DialogBox';
import LogoProvider from '../images/logotypes/LogoProvider';

const StyledCard = styled.div`
  cursor: pointer;
  text-align: left;
  ${cardStyles};
  transition: transform 200ms ${acceleratedEasing};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 27rem;

  @media ${breakpoints.md} {
    min-height: 28rem;

    &:hover {
      transform: rotate(5deg);
    }
  }
`;

const Footer = styled.footer`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  flex: 0 0 40%;
`;

const Author = styled.div`
  flex: 0 1 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
`;

const StyledIconCircle = styled(IconCircle)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.5rem;

  @media ${breakpoints.md} {
    margin-left: 1.5rem;
  }
`;

const StartupDialogCard = ({
  title,
  question,
  answer,
  logo,
  author,
  ...props
}) => (
  <StyledCard {...props} tabIndex="0">
    <div>
      <PKOH4>{title}</PKOH4>
      <DialogBox blue arrow="left" text={question} />
      <DialogBox arrow="bottom" text={answer} />
      <Footer>
        <Logo>
          <LogoProvider name={logo} />
        </Logo>
        <Author>
          <span>{author}</span>
          <StyledIconCircle small>
            <FaPlus />
          </StyledIconCircle>
        </Author>
      </Footer>
    </div>
  </StyledCard>
);

StartupDialogCard.propTypes = {
  title: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,
  logo: PropTypes.node,
  author: PropTypes.string,
};

export default StartupDialogCard;
