import React from 'react';
import appInteractionIcon from '../../images/app-interaction-icon.svg';

const AppInteractionIcon = props => {
  return (
    <img src={appInteractionIcon} alt="AppInteractionIcon" {...props} />
  );
};

export default AppInteractionIcon;
