import React from 'react';
import wishes6 from '../../images/wishes-6.svg';

const Wishes6Shape = props => {
  return (
    <img src={wishes6} alt="Wishes6Shape" {...props} />
  );
};

export default Wishes6Shape;
