import React from 'react';
import styled from 'styled-components';
import InfoCircle from '../atoms/InfoCircle';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderBlack,
  infoHeadersTransformations,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';

const StyledInfoCircle = styled(InfoCircle)`
`;

const Header = styled.header`
  & > ${InfoHeaderBlack} {
    display: block;
    margin-top: 0.25rem;
  }
`;

const Info1 = ({ ...props }) => (
  <StyledInfoCircle
    {...props}
    header={
      <Header>
        <FormattedMessage
          id="home.informationSection.items.0.header"
          values={infoHeadersTransformations}
        />
      </Header>
    }
  >
    <FormattedHTMLMessage id="home.informationSection.items.0.text" />
  </StyledInfoCircle>
);

export default Info1;
