import styled, { css } from 'styled-components';
import IconButtonBox from './IconButtonBox';
import breakpoints from '../../utils/breakpoints';
import { FaArrowLeft } from 'react-icons/all';
import React from 'react';
import colors from '../../utils/colors';

const StyledArrowLeftIconButtonBox = styled(IconButtonBox)`
  position: absolute;
  bottom: 0;

  ${({ top }) =>
    top &&
    css`
      bottom: auto;
      top: 0;
    `};

  @media ${breakpoints.md} {
    right: 10rem;
  }

  ${({ overflow }) =>
    overflow &&
    css`
      @media ${breakpoints.lg} {
        right: 15rem;
      }

      @media ${breakpoints.xl} {
        right: 20rem;
      }

      @media ${breakpoints.desktop} {
        right: 30rem;
      }
    `};

  &.slick-disabled {
    cursor: not-allowed;
    background-color: ${colors.gray};

    &:hover {
      background-color: ${colors.gray};
    }
  }
`;

const SlickArrowLeft = props => (
  <StyledArrowLeftIconButtonBox {...props} style={{}}>
    <FaArrowLeft />
  </StyledArrowLeftIconButtonBox>
);

export default SlickArrowLeft;
