import React from 'react';
import tips3 from '../../images/tips-3.svg';

const Tips3Shape = props => {
  return (
    <img src={tips3} alt="Tips3Shape" {...props} />
  );
};

export default Tips3Shape;
