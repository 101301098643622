import React from 'react';
import wishes2 from '../../images/wishes-2.svg';

const Wishes2Shape = props => {
  return (
    <img src={wishes2} alt="Wishes2Shape" {...props} />
  );
};

export default Wishes2Shape;
