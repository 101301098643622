import styled from 'styled-components';
import React, { useState } from 'react';
import colors from '../../utils/colors';
import Container from '../atoms/Container';
import { SectionHeader } from '../atoms/SectionHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import ExpertStudy from '../organisms/ExpertStudy';
import Section from '../atoms/Section';
import ExpertStudyListItem from '../organisms/ExpertStudyListItem';
import { Overlay, QuotePictureWrapper } from '../molecules/QuotePictures';
import Slick from '../organisms/Slick';
import breakpoints from '../../utils/breakpoints';
import { containerStyles } from '../../utils/styles';
import { useCallbackRef } from 'use-callback-ref';
import ExpertStudyPopupSection from './ExpertStudyPopupSection';
import { messages } from '../../i18n/translations';
import ExpertPhotoProvider from '../images/ExpertPhotoProvider';
import { isIE } from 'react-device-detect';

const StyledSection = styled(Section)`
  background-color: ${colors.grayLighter};
`;

const SlickItem = styled.div`
  ${containerStyles};
`;

const NavSlickItem = styled.div`
  width: ${({ size }) => (size === 'lg' ? '11.25rem' : '8.2rem')} !important;
  padding: 0 0.875rem;
  box-sizing: content-box;
`;

const StyledExpertStudyListItem = styled(ExpertStudyListItem)`
  flex-grow: 0;
  max-width: 13rem;
  margin-bottom: 1.5rem;
`;

const StyledNavSlick = styled(Slick)`
  margin-left: 0 !important;
  margin-right: 0 !important;

  & > .slick-list {
    margin-bottom: 0;
  }

  @media ${breakpoints.toMd} {
    display: none;
  }

  .slick-current ${StyledExpertStudyListItem} {
    ${QuotePictureWrapper} .gatsby-image-wrapper {
      filter: none;
    }

    ${Overlay} {
      opacity: 0;
    }
  }
`;

const excerptSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  touchMove: false,
  adaptiveHeight: true,
  autoplay: true && !isIE,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 991,
      settings: {},
    },
    {
      breakpoint: 767,
      settings: {
        autoplay: false,
        touchMove: true,
        dots: true,
      },
    },
  ],
};

const navSliderSettings = {
  slidesToShow: 6,
  slidesToScroll: 1,
  dots: true,
  swipeToSlide: true,
  focusOnSelect: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

const ExpertStudiesSection = () => {
  const [, forceUpdate] = useState({});
  const [dialogProps, setDialogProps] = useState(null);

  const excerptSliderRef = useCallbackRef(null, () => forceUpdate({}));
  const navSliderRef = useCallbackRef(null, () => forceUpdate({}));
  const intl = useIntl();

  return (
    <>
      {dialogProps && (
        <ExpertStudyPopupSection
          {...dialogProps}
          onChange={setDialogProps}
          close={() => setDialogProps(null)}
        />
      )}
      <StyledSection id={intl.formatMessage({ id: 'home.expertsSection.id' })}>
        <Container>
          <SectionHeader indent rocketId="nav-rocket-3">
            <FormattedMessage id="home.expertsSection.header" />
          </SectionHeader>

          <Slick
            {...excerptSliderSettings}
            ref={excerptSliderRef}
            asNavFor={navSliderRef.current}
            {...(dialogProps ? { autoplay: false } : {})}
          >
            {messages[intl.locale].home.expertsSection.items.map(_ => (
              <SlickItem>
                <ExpertStudy
                  onClick={() => setDialogProps(_)}
                  images={_.authors.map(a => (
                    <ExpertPhotoProvider name={a} />
                  ))}
                  authors={_.authors.map(a => ({
                    name: a,
                    company: _.company,
                  }))}
                  title={_.title}
                  excerpt={_.excerpt}
                />
              </SlickItem>
            ))}
          </Slick>

          <StyledNavSlick
            {...navSliderSettings}
            ref={navSliderRef}
            asNavFor={excerptSliderRef.current}
          >
            {messages[intl.locale].home.expertsSection.items.map(_ => (
              <NavSlickItem size={_.authors.length > 1 ? 'lg' : 'sm'}>
                <StyledExpertStudyListItem
                  title={_.title}
                  images={_.authors.map(a => (
                    <ExpertPhotoProvider name={a} />
                  ))}
                  authors={_.authors.map(a => ({
                    name: a,
                    company: _.company,
                  }))}
                />
              </NavSlickItem>
            ))}
          </StyledNavSlick>
        </Container>
      </StyledSection>
    </>
  );
};

export default ExpertStudiesSection;
