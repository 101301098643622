import styled, { css } from 'styled-components';
import { PKOH3 } from '../atoms/Headers';
import GradientHr from '../atoms/GradientHr';
import Paragraph from '../atoms/Paragraph';
import React from 'react';
import breakpoints from '../../utils/breakpoints';
import IdeaIcon from '../images/IdeaIcon';
import MessageArrowsIcon from '../images/MessageArrowsIcon';
import ConnectionsIcon from '../images/ConnectionsIcon';

const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 8vh;
  }

  @media ${breakpoints.lg} {
    display: grid;
    grid-template-areas:
      'title .'
      'hr hr'
      'text icon';

    ${({ index }) =>
      index % 2 === 1 &&
      css`
        grid-template-areas:
          '. title'
          'hr hr'
          'icon text';
      `};

    grid-template-columns: auto 18rem;

    ${({ index }) =>
      index % 2 === 1 &&
      css`
        grid-template-columns: 18rem auto;
      `};
  }
`;

const IconWrapper = styled.div`
  grid-area: icon;
  width: 8.75rem;
  height: 8.75rem;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
    to bottom,
    ${({ index }) => {
      switch (index) {
        case 0:
          return `#E63670, #A81552`;
        case 1:
          return `#FFCD1F, #ED8923`;
        case 2:
          return `#B6D11F, #50B328`;
        case 3:
          return `#FF8C00, #D34211`;
      }
    }}
  );

  @media ${breakpoints.md} {
    align-self: center;
    justify-self: flex-end;

    ${({ index }) =>
      index % 2 === 1 &&
      css`
        justify-self: flex-start;
      `};
  }

  @media ${breakpoints.lg} {
    width: 12.5rem;
    height: 12.5rem;
  }
`;

const SectionHeader = styled(PKOH3)`
  grid-area: title;
  font-size: 1.5625rem;
  line-height: 1.2;
  font-weight: 700;

  @media ${breakpoints.lg} {
    font-size: 3.125rem;
  }
`;

const icons = [<IdeaIcon />, <MessageArrowsIcon />, <ConnectionsIcon />];

const ExpertStudySection = ({ title, content, index }) => (
  <Section index={index}>
    <IconWrapper index={index}>{icons[index]}</IconWrapper>
    <SectionHeader>{title}</SectionHeader>
    <GradientHr style={{ gridArea: 'hr' }} />
    <Paragraph style={{ gridArea: 'text' }}>{content}</Paragraph>
  </Section>
);

export default ExpertStudySection;
