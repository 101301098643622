import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

const MobileHideOverflow = styled.div`
  @media ${breakpoints.toMd} {
    margin-left: -25px;
    margin-right: -25px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    & > * {
      flex-shrink: 0;
    }
  }

  @media ${breakpoints.md} {
    display: inline-block;
    margin: 0 auto;
  }
`;

export default MobileHideOverflow;
