import React from 'react';
import { FaComments } from 'react-icons/fa';
import styled from 'styled-components';
import colors from '../../utils/colors';
import fonts from '../../utils/fonts';
import Container from '../atoms/Container';
import { PKOH4 } from '../atoms/Headers';
import Popup from '../organisms/Popup';
import breakpoints from '../../utils/breakpoints';
import Footer from './Footer';
import DialogQuestionsExpandableList from '../organisms/DialogQuestionsExpandableList';
import LogoProvider from '../images/logotypes/LogoProvider';
import { SectionHeader } from '../atoms/SectionHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import Section from '../atoms/Section';
import { messages } from '../../i18n/translations';
import StartupDialogCard from '../organisms/StartupDialogCard';
import truncate from '../../utils/truncate';
import Slick from '../organisms/Slick';

const Header = styled.header`
  display: grid;
  grid-template-areas: 'icon' 'title' 'subtitle' 'logo' 'hr';
  margin-bottom: 2rem;
  padding-top: 3.75rem;

  @media ${breakpoints.md} {
    padding-top: 5rem;
    grid-template-areas:
      'icon title title'
      '. logo subtitle';
    grid-template-columns: 3.75rem 14rem 1fr;
    grid-column-gap: 2rem;
    align-items: center;
    justify-content: flex-start;
  }

  @media ${breakpoints.md} {
    padding-top: 10vh;
  }
`;

const IconContainer = styled.div`
  grid-area: icon;
  flex-shrink: 0;

  @media ${breakpoints.md} {
    align-self: flex-start;
  }
`;

const StyledDialogIcon = styled(FaComments)`
  font-size: 3rem;
  color: ${colors.grayLight};

  @media ${breakpoints.md} {
    font-size: 3.75rem;
  }
`;

const Title = styled.h2`
  grid-area: title;
  font-size: 2.5rem;
  font-family: ${fonts.pko};
  font-weight: bold;
  line-height: 1.125;
  margin: 0.625rem 0 1rem 0;
  align-self: flex-start;

  @media ${breakpoints.md} {
    padding-top: 1rem;
    margin: 0 0 2rem 0;
    font-size: 3rem;
  }

  @media ${breakpoints.lg} {
    font-size: 4rem;
    margin: 0 0 3rem 0;
  }
`;

const Subtitle = styled(PKOH4)`
  grid-area: subtitle;
  font-weight: bold;
`;

const Logo = styled.div`
  margin-top: 3.25rem;
  grid-area: logo;
  max-width: 12.5rem;

  @media ${breakpoints.md} {
    margin-top: 0;
  }
`;

const Hr = styled.div`
  width: 3.25rem;
  background-color: ${colors.red};
  border: 0;
  height: 0.5rem;
  margin: 3.75rem 0 0;

  @media ${breakpoints.md} {
    margin-top: 1rem;
    width: 100%;
  }
`;

const MobileHr = styled(Hr)`
  grid-area: hr;

  @media ${breakpoints.md} {
    display: none !important;
  }
`;

const DesktopHr = styled(Hr)`
  @media ${breakpoints.toMd} {
    display: none !important;
  }
`;

const Dialog = styled.div`
  padding-bottom: 2rem;

  @media ${breakpoints.md} {
    padding: 5rem 0 3rem 3rem;
    background-color: ${colors.grayLighter};
  }
`;

const StyledContainer = styled(Container)`
  @media ${breakpoints.md} {
    position: relative;
    border-left: 1px solid ${colors.grayLight};
  }
`;

const StyledSlick = styled(Slick)`
  margin-top: -1rem;

  @media ${breakpoints.md} {
    margin-top: -8rem;
    padding-top: 7rem;
  }

  @media ${breakpoints.lg} {
    margin-top: -8.5rem;
  }
`;

const SlickItem = styled.div`
  padding: 1.5rem 1rem;
  text-align: center;

  @media ${breakpoints.md} {
    padding: 2.5rem 1rem;
  }
`;

const slickSettings = {
  overflow: true,
  topArrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        touchMove: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: true,
        touchMove: true,
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
      },
    },
  ],
};

const DialogPopupSection = ({
  close,
  title,
  subtitle,
  logo,
  questions,
  onChange,
}) => {
  const locale = useIntl().locale;

  return (
    <Popup close={close}>
      <Container id="interview-start">
        <Header>
          <IconContainer>
            <StyledDialogIcon />
            <DesktopHr />
          </IconContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Logo>
            <LogoProvider name={logo} />
          </Logo>
          <MobileHr />
        </Header>
      </Container>

      <Dialog>
        <StyledContainer>
          <DialogQuestionsExpandableList questions={questions} />
        </StyledContainer>
      </Dialog>

      <Section style={{ backgroundColor: 'white' }}>
        <Container noGutter fluidRight>
          <SectionHeader gray>
            <FormattedMessage id="home.startupDialogSection.header" />
          </SectionHeader>

          <StyledSlick {...slickSettings}>
            {messages[locale].home.startupDialogSection.items.map(_ => (
              <SlickItem>
                <StartupDialogCard
                  onClick={() => {
                    onChange(_);
                    setTimeout(
                      () =>
                        document
                          .getElementById('interview-start')
                          .scrollIntoView(),
                      16
                    );
                  }}
                  title={_.title}
                  question={_.questions[0].question}
                  answer={truncate(_.questions[0].answer, 200)}
                  author={_.author}
                  logo={_.company}
                />
              </SlickItem>
            ))}
          </StyledSlick>
        </Container>
      </Section>

      <Footer onRedirect={close} />
    </Popup>
  );
};

export default DialogPopupSection;
