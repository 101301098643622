import React from 'react';
import unlockedIcon from '../../images/unlocked-icon.svg';

const UnlockedIcon = props => {
  return (
    <img src={unlockedIcon} alt="UnlockedIcon" {...props} />
  );
};

export default UnlockedIcon;
