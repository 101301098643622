import React, { createElement, useEffect, useState } from 'react';
import Container from '../atoms/Container';
import { SectionHeader } from '../atoms/SectionHeader';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import Section from '../atoms/Section';
import styled from 'styled-components';
import colors from '../../utils/colors';
import TextCard, { styleTextCardShape } from '../organisms/TextCard';
import Paragraph from '../atoms/Paragraph';
import breakpoints from '../../utils/breakpoints';
import Slick from '../organisms/Slick';
import range from '../../utils/range';
import Tips0Shape from '../images/Tips0Shape';
import Tips1Shape from '../images/Tips1Shape';
import Tips2Shape from '../images/Tips2Shape';
import Tips3Shape from '../images/Tips3Shape';
import Tips4Shape from '../images/Tips4Shape';

const StyledSection = styled(Section)`
  background-color: ${colors.grayLighter};
  padding-top: 1rem;

  @media ${breakpoints.md} {
    padding-top: 3rem;
  }

  @media ${breakpoints.lg} {
    padding-top: 3rem;
  }
`;

const StyledSlick = styled(Slick)`
  margin-top: -2.5rem;

  @media ${breakpoints.md} {
    margin-top: -3.5rem;
  }
`;

const SlickItem = styled.div`
  padding: 2.5rem 1rem;
  text-align: center;

  @media ${breakpoints.md} {
    padding: 4rem 1rem;
  }
`;

const founderTipsIcons = [
  Tips0Shape,
  Tips1Shape,
  Tips2Shape,
  Tips3Shape,
  Tips4Shape,
].map(styleTextCardShape);

const FounderTipsSection = () => {
  const [showSlider, setShowSlider] = useState(false);

  useEffect(() => {
    setShowSlider(true);
  }, []);

  return (
    <StyledSection
      id={useIntl().formatMessage({ id: 'home.founderTipsSection.id' })}
    >
      <Container>
        <SectionHeader
          horizontal
          rocketId="nav-rocket-6"
          subtitle={
            <FormattedHTMLMessage id="home.founderTipsSection.header.subtitle" />
          }
        >
          <FormattedHTMLMessage id="home.founderTipsSection.header.main" />
        </SectionHeader>
      </Container>

      <Container noGutter fluidRight>
        {showSlider && (
          <StyledSlick progressbar overflow>
            {range(0, 4).map(i => (
              <SlickItem key={i}>
                <TextCard
                  title={
                    <FormattedHTMLMessage
                      id={`home.founderTipsSection.items.${i}.title`}
                    />
                  }
                  number={i + 1}
                  shape={createElement(founderTipsIcons[i])}
                >
                  <Paragraph>
                    <FormattedHTMLMessage
                      id={`home.founderTipsSection.items.${i}.text`}
                    />
                  </Paragraph>
                </TextCard>
              </SlickItem>
            ))}
          </StyledSlick>
        )}
      </Container>
    </StyledSection>
  );
};

export default FounderTipsSection;
