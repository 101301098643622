import React from 'react';
import keyIcon from '../../images/key-icon.svg';

const KeyIcon = props => {
  return (
    <img src={keyIcon} alt="KeyIcon" {...props} />
  );
};

export default KeyIcon;
