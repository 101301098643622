import React from 'react';
import Container from '../atoms/Container';
import Section from '../atoms/Section';
import { PKOH1 } from '../atoms/Headers';
import colors from '../../utils/colors';
import styled, { keyframes } from 'styled-components';
import { FaArrowRight } from 'react-icons/all';
import breakpoints from '../../utils/breakpoints';
import LinkBox from '../atoms/LinkBox';
import StartupsLogoSlider from '../organisms/StartupsLogoSlider';
import { getRoute } from '../../utils/router';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import LogotypeSliderImageDesktop from '../images/LogotypeSliderImageDesktop';
import { SectionHeader } from '../atoms/SectionHeader';

const StyledContainer = styled(Container)`
  display: grid;
  grid-template-areas: 'a' 'b' 'c';
  grid-row-gap: 1rem;

  @media ${breakpoints.md} {
    grid-row-gap: 2rem;
  }

  @media ${breakpoints.lg} {
    grid-template-areas:
      'a b'
      'c b';
    grid-template-columns: 1.25fr 2fr;
    grid-column-gap: 3rem;
  }
`;

const StyledSectionHeader = styled(SectionHeader)`
  margin-bottom: 0 !important;
`;

const StyledLinkBox = styled(LinkBox)`
  grid-area: c;
  display: flex;
  justify-content: space-between;
  max-width: 10.75rem;

  @media ${breakpoints.md} {
    max-width: 15rem;
  }
`;

const StyledFaArrowRight = styled(FaArrowRight)`
  font-size: 1rem;

  @media ${breakpoints.md} {
    font-size: 1.875rem;
  }
`;

const SliderWrapper = styled.div`
  grid-area: b;
  position: relative;
  margin-left: -25px;
  margin-right: -25px;
  overflow: hidden;
  height: 18.75rem;

  @media ${breakpoints.md} {
    margin-left: -30px;
    margin-right: -30px;
  }

  @media ${breakpoints.lg} {
    height: 30rem;
  }

  @media ${breakpoints.ie} {
    display: none !important;
  }
`;

const moveTrack = keyframes`
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-100%, 0);
  }
`;

const IELogosSlider = styled.div`
  display: none;
  max-width: 100%;
  overflow: hidden;

  & > .gatsby-image-wrapper {
    animation: ${moveTrack} 50s linear infinite;
    margin-left: 50%;
  }

  @media ${breakpoints.ie} {
    display: block !important;
  }

  margin-bottom: 2rem;
`;

const Gradient = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  z-index: 10;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const MeetTheStartupsSection = () => (
  <Section id={useIntl().formatMessage({ id: 'home.meetStartupsSection.id' })}>
    <StyledContainer>
      <StyledSectionHeader rocketId="nav-rocket-7" hr={false}>
        <FormattedHTMLMessage id="home.meetStartupsSection.header" />
      </StyledSectionHeader>
      <IELogosSlider>
        <LogotypeSliderImageDesktop />
      </IELogosSlider>
      <StyledLinkBox to={getRoute('ComponentStartups', useIntl())}>
        <span>
          <FormattedMessage id="home.meetStartupsSection.button" />
        </span>
        <StyledFaArrowRight />
      </StyledLinkBox>
      <SliderWrapper>
        <StartupsLogoSlider />
        <Gradient />
      </SliderWrapper>
    </StyledContainer>
  </Section>
);

export default MeetTheStartupsSection;
