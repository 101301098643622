import React, { forwardRef } from 'react';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import { deceleratedEasing } from '../../utils/styles';
import SlickArrowLeft from '../atoms/SlickArrowLeft';
import SlickArrowRight from '../atoms/SlickArrowRight';
import SlickDot from '../atoms/SlickDot';
import SlickDotsWrapper from '../atoms/SlickDotsWrapper';

const StyledSlider = styled(Slider)`
  overflow: hidden;
  margin-right: -25px;
  margin-left: -25px;

  @media ${breakpoints.md} {
    margin-left: -30px;
    margin-right: -30px;

    ${({ overflow }) =>
      overflow &&
      css`
        .slick-list {
          width: 135%;
        }
      `};

    .slick-list {
      margin-bottom: 2rem;
    }
  }

  @media ${breakpoints.lg} {
    margin-left: 0;
    margin-right: -40px;
  }

  @media ${breakpoints.xl} {
    margin-right: -50px;
  }

  @media ${breakpoints.desktop} {
    ${({ overflow }) =>
      overflow &&
      css`
        .slick-list {
          width: 110%;
        }
      `};
  }
`;

const settings = {
  dots: true,
  speed: 400,
  slidesToScroll: 1,
  slidesToShow: 3,
  infinite: true,
  cssEase: deceleratedEasing,
  touchMove: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        touchMove: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        touchMove: true,
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
      },
    },
  ],
};

const Slick = forwardRef(
  ({ children, overflow, progressbar, topArrows, ...props }, ref) => (
    <StyledSlider
      {...settings}
      appendDots={dots => (
        <SlickDotsWrapper progressbar={progressbar} overflow={overflow}>
          {dots}
        </SlickDotsWrapper>
      )}
      customPaging={() => (
        <SlickDot progressbar={progressbar} overflow={overflow} />
      )}
      prevArrow={<SlickArrowLeft overflow={overflow} top={topArrows} />}
      nextArrow={<SlickArrowRight overflow={overflow} top={topArrows} />}
      overflow={overflow}
      {...props}
      ref={ref}
    >
      {children}
    </StyledSlider>
  )
);

export default Slick;
