import React from 'react';
import { FaComment } from 'react-icons/fa';
import styled from 'styled-components';
import colors from '../../utils/colors';
import fonts from '../../utils/fonts';
import ExpandableParagraph from '../molecules/ExpandableParagraph';
import breakpoints from '../../utils/breakpoints';
import Paragraph from '../atoms/Paragraph';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

const QuestionIcon = styled(FaComment)`
  font-size: 2.625rem;
  color: ${colors.blue};

  @media ${breakpoints.lg} {
    position: absolute;
    left: 0;
    transform: translate(-50%, -10%);
  }
`;

const Question = styled.h4`
  font-family: ${fonts.barlowCondensed};
  font-size: 1.5625rem;
  color: ${colors.black};
  font-weight: 600;
  margin-top: 0.6875rem;

  @media ${breakpoints.lg} {
    font-size: 1.875rem;
  }
`;

const AnswerIcon = styled(FaComment)`
  font-size: 2.625rem;
  color: ${colors.grayLight};
  transform: scaleX(-1);

  @media ${breakpoints.lg} {
    position: absolute;
    left: 0;
    transform: translate(-50%, -10%) scaleX(-1);
  }
`;

const DialogQuestionAnswer = ({ question, answer, ...props }) => (
  <Wrapper {...props}>
    <QuestionIcon />
    <Question dangerouslySetInnerHTML={{ __html: question }} />
    <AnswerIcon />
    <Paragraph dangerouslySetInnerHTML={{ __html: answer }} style={{ marginTop: '0.6875rem' }} />
  </Wrapper>
);

export default DialogQuestionAnswer;
