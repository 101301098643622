import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import Section from '../atoms/Section';
import Info3 from '../molecules/Info3';
import Info4 from '../molecules/Info4';
import Info5 from '../molecules/Info5';
import Info6 from '../molecules/Info6';
import Info7 from '../molecules/Info7';
import Info8 from '../molecules/Info8';
import Info9 from '../molecules/Info9';
import Info10 from '../molecules/Info10';
import MobileHideOverflow from '../atoms/MobileHideOverflow';
import breakpoints from '../../utils/breakpoints';
import Slick from '../organisms/Slick';
import { useMediaQuery } from 'react-responsive/src';
import onlyLarge from '../../utils/onlyLarge';
import onlyMobile from '../../utils/onlyMobile';

const StyledSection = styled(Section)`
  padding-top: 0 !important;
`;

const SlickItem = styled.div`
  padding: 0 25px 1.5rem;
  position: relative;
  text-align: center;

  @media ${breakpoints.lg} {
    padding-bottom: 0;
    text-align: ${({ align }) => align};
  }
`;

const StyledSlick = styled(Slick)`
  @media ${breakpoints.lg} {
    margin-left: 0;
    margin-right: 0;
  }

  .slick-slide {
    padding-top: 3rem;

    @media ${breakpoints.md} {
      padding-top: 4.5rem;
    }

    @media ${breakpoints.lg} {
      padding-top: 6.5625rem;
    }
  }
`;

const slickSettings = {
  slidesToScroll: 2,
  slidesToShow: 2,
  infinite: false,
  lazyLoad: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        touchMove: true,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
      },
    },
  ],
};

const Info4Desktop = styled(Info4)`
  @media ${breakpoints.toLg} {
    display: none !important;
  }
`;
const Info4Mobile = styled(Info4)`
  @media ${breakpoints.lg} {
    display: none !important;
  }
`;
const MobileHideOverflowDesktop = styled(MobileHideOverflow)`
  @media ${breakpoints.toLg} {
    display: none !important;
  }
`;
const MobileHideOverflowMobile =  styled(MobileHideOverflow)`
  @media ${breakpoints.lg} {
    display: none !important;
  }
`;;

const Slider = () => (
  <StyledSlick {...slickSettings}>
    <SlickItem align="left">
      <Info4Desktop />
      <MobileHideOverflowMobile>
        <Info3 />
      </MobileHideOverflowMobile>
    </SlickItem>
    <SlickItem align="right">
      <Info4Mobile />
      <MobileHideOverflowDesktop>
        <Info3 />
      </MobileHideOverflowDesktop>
    </SlickItem>
    <SlickItem align="left">
      <Info5 />
    </SlickItem>
    <SlickItem align="right">
      <MobileHideOverflow>
        <Info6 />
      </MobileHideOverflow>
    </SlickItem>
    <SlickItem align="left">
      <Info7 />
    </SlickItem>
    <SlickItem align="right">
      <MobileHideOverflow>
        <Info8 />
      </MobileHideOverflow>
    </SlickItem>
    <SlickItem align="left">
      <Info9 />
    </SlickItem>
    <SlickItem align="right">
      <MobileHideOverflow>
        <Info10 />
      </MobileHideOverflow>
    </SlickItem>
  </StyledSlick>
);

const InformationSliderSection = () => {
  const [, forceUpdate] = useState({});

  useEffect(() => {
    const reload = () => forceUpdate({});

    window.addEventListener('resize', reload);

    return () => window.removeEventListener('resize', reload);
  });

  return (
    <StyledSection>
      <Container>
        <Slider />
      </Container>
    </StyledSection>
  );
};

export default InformationSliderSection;
