import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

const DesktopInfoImageWrapper = styled.div`
  display: none;

  @media ${breakpoints.lg} {
    display: block;
    position: absolute;
    overflow: hidden;
    top: -5rem;
    left: 7rem;
    width: 22rem;
    max-width: 100%;
  }

  @media ${breakpoints.xxl} {
    width: 30rem;
  }

  & > .gatsby-image-wrapper {
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

export default DesktopInfoImageWrapper;
