import React from 'react';
import styled, { keyframes } from 'styled-components';
import LogotypeSliderImage from '../../images/logotypes-slider.jpg';
import breakpoints from '../../utils/breakpoints';

const SliderWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const moveTrack = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-50%, 0, 0);
  }
`;

const LogosTrack = styled.div`
  position: absolute;
  display: flex;
  width: auto;
  height: 100%;
  animation: ${moveTrack} 40s linear infinite;
`;

const StyledLogotypeSliderImage = styled.img`
  max-height: 100% !important;
`;

const StartupsLogoSlider = () => (
  <SliderWrapper>
    <LogosTrack>
      <StyledLogotypeSliderImage src={LogotypeSliderImage} />
      <StyledLogotypeSliderImage src={LogotypeSliderImage} />
    </LogosTrack>
  </SliderWrapper>
);

export default StartupsLogoSlider;
