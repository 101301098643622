import React from 'react';
import tips0 from '../../images/tips-0.svg';

const Tips0Shape = props => {
  return (
    <img src={tips0} alt="Tips0Shape" {...props} />
  );
};

export default Tips0Shape;
