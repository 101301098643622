import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import colors from '../../utils/colors';
import breakpoints from '../../utils/breakpoints';

const DialogBoxContainer = styled.div`
  position: relative;
  font-size: 0.875rem;
  font-weight: 300;
  padding: 1rem 1rem 1rem 1.5rem;
  border-radius: 0.875rem;
  line-height: 1.45;
  margin: 1rem -0.75rem;
  background-color: ${({ blue }) => (blue ? colors.blue : colors.grayLight)};
  color: ${({ blue }) => (blue ? 'white' : colors.black)};

  @media ${breakpoints.md} {
    font-size: 1.0625rem;
    margin: 1rem 0;
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
  background-color: ${({ blue }) => (blue ? colors.blue : colors.grayLight)};

  ${({ position }) =>
    position === 'left' &&
    css`
      top: 1.5rem;
      left: 0;
      transform: translateX(-40%) rotate(45deg);
    `};

  ${({ position }) =>
    position === 'bottom' &&
    css`
      left: 2rem;
      bottom: 0;
      transform: translateY(40%) rotate(45deg);
    `};
`;

const DialogBox = ({ blue, arrow, text, ...props }) => (
  <DialogBoxContainer blue={blue} {...props}>
    {arrow && <Arrow blue={blue} position={arrow} />}
    <span>{text}</span>
  </DialogBoxContainer>
);

DialogBox.propTypes = {
  blue: PropTypes.bool,
  arrow: PropTypes.oneOf(['left', 'bottom']),
  text: PropTypes.string,
};

export default DialogBox;
