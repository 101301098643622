import React, { useEffect, useState } from 'react';
import DialogQuestionAnswer from '../molecules/DialogQuestionAnswer';
import { FaPlus } from 'react-icons/all';
import IconButtonCircle from '../atoms/IconButtonCircle';

const DialogQuestionsExpandableList = ({ questions }) => {
  const [length, setLength] = useState(2);

  useEffect(() => setLength(2), [questions]);

  return (
    <>
      {questions.slice(0, length).map(q => (
        <DialogQuestionAnswer
          key={q.question}
          question={q.question}
          answer={q.answer}
        />
      ))}
      {questions.length > length && (
        <div style={{ textAlign: 'center' }}>
          <IconButtonCircle onClick={() => setLength(questions.length)}>
            <FaPlus />
          </IconButtonCircle>
        </div>
      )}
    </>
  );
};

export default DialogQuestionsExpandableList;
