import Popup from '../organisms/Popup';
import Footer from './Footer';
import React from 'react';
import styled from 'styled-components';
import Paragraph from '../atoms/Paragraph';
import { FaQuoteRight } from 'react-icons/all';
import fonts from '../../utils/fonts';
import QuotePictures from '../molecules/QuotePictures';
import { PKOH2 } from '../atoms/Headers';
import BlueBackground from '../atoms/BlueBackground';
import GradientHr from '../atoms/GradientHr';
import RedShortHr from '../atoms/RedShortHr';
import ExpertStudyExcerpt from '../atoms/ExpertStudyExcerpt';
import ExpertStudySection from '../molecules/ExpertStudySection';
import breakpoints from '../../utils/breakpoints';
import Container from '../atoms/Container';
import ExpertPhotoProvider from '../images/ExpertPhotoProvider';
import Slick from '../organisms/Slick';
import Section from '../atoms/Section';
import { SectionHeader } from '../atoms/SectionHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from '../../i18n/translations';
import colors from '../../utils/colors';
import truncate from '../../utils/truncate';

const StudyContainer = styled(Container)`
  padding-top: 3.75rem;
  padding-bottom: 5.625rem;
  color: white;

  @media ${breakpoints.md} {
    padding-top: 6rem;
  }
`;

const Header = styled.header`
  @media ${breakpoints.md} {
    display: grid;
    grid-template-areas:
      'qc title title title'
      'hr hr hr hr'
      'author author intro intro';
    grid-template-columns: 3.75rem 2fr 2fr 2fr;
    grid-column-gap: 3rem;
    margin-bottom: 7vh;
  }

  @media ${breakpoints.lg} {
    grid-template-areas:
      'qc title title title'
      'hr hr hr hr'
      '. author intro intro';
    grid-template-columns: 3.75rem 1.75fr 2fr 2fr;
  }
`;

const QuoteContainer = styled.div`
  @media ${breakpoints.md} {
    grid-area: qc;
  }
`;

const RedShortHrDesktop = styled(RedShortHr)`
  @media ${breakpoints.toMd} {
    display: none !important;
  }
`;
const RedShortHrMobile = styled(RedShortHr)`
  @media ${breakpoints.md} {
    display: none !important;
  }
`;

const StyledQuote = styled(FaQuoteRight)`
  font-size: 2.625rem;
  color: #9bc9fb;

  @media ${breakpoints.md} {
    font-size: 3.75rem;
  }
`;

const Title = styled(PKOH2)`
  font-size: 2.5rem;
  line-height: 1.125;
  margin: 0.625rem 0 1rem 0;

  @media ${breakpoints.md} {
    margin-top: 0;
    grid-area: title;
    font-size: 3rem;
  }

  @media ${breakpoints.lg} {
    font-size: 4rem;
  }
`;

const AuthorContainer = styled.div`
  @media ${breakpoints.md} {
    grid-area: author;
  }
`;

const Author = styled.div`
  font-size: 2.5rem;
  font-family: ${fonts.pko};
  font-weight: bold;
  line-height: 1.125;
  margin: 0.75rem 0 0.625rem 0;
  max-width: 10rem;
`;

const Company = styled.div`
  font-size: 1.0625rem;
  line-height: 1.125;
  color: #9bc9fb;
  margin-top: 0.625rem;
`;

const StyledGradientHr = styled(GradientHr)`
  @media ${breakpoints.md} {
    grid-area: hr;
    margin-bottom: 3.75rem;
  }
`;

const Intro = styled.div`
  margin-top: 3.75rem;

  @media ${breakpoints.md} {
    grid-area: intro;
    margin-top: 0;
    align-self: center;
  }
`;

const IntroParagraph = styled(Paragraph)`
  margin-bottom: 3.75rem;

  @media ${breakpoints.md} {
    margin-bottom: 0;
  }
`;

const SliderSection = styled(Section)`
  background-color: white;

  @media ${breakpoints.lg} {
    padding-bottom: 0;
  }
`;

const StyledSlick = styled(Slick)`
  margin-top: -1rem;

  @media ${breakpoints.md} {
    margin-top: -8rem;
    padding-top: 7rem;
  }

  @media ${breakpoints.lg} {
    margin-top: -8.5rem;
  }
`;

const SlickItem = styled.div`
  padding: 1rem;
  text-align: left;
  cursor: pointer;
`;

const slickSettings = {
  slidesToShow: 4,
  overflow: true,
  topArrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        touchMove: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: true,
        touchMove: true,
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
        adaptiveHeight: true,
      },
    },
  ],
};

const ExpertStudyPopupSection = ({
  close,
  title,
  authors,
  company,
  excerpt,
  intro,
  sections,
  onChange,
}) => {
  const locale = useIntl().locale;

  return (
    <Popup close={close}>
      <BlueBackground>
        <StudyContainer id="expert-start">
          <Header>
            <QuoteContainer>
              <StyledQuote />
              <RedShortHrDesktop />
            </QuoteContainer>
            <Title>{title}</Title>
            <RedShortHrMobile />
            <AuthorContainer>
              <QuotePictures
                style={{ gridArea: 'a' }}
                images={authors.map(a => (
                  <ExpertPhotoProvider name={a} />
                ))}
                large
              />
              <Author>
                {authors[0]}
                {authors[1] && (
                  <>
                    ,<br />
                    {authors[1]}
                  </>
                )}
              </Author>
              <Company>{company}</Company>
            </AuthorContainer>
            <StyledGradientHr />

            <Intro>
              <ExpertStudyExcerpt>{excerpt}</ExpertStudyExcerpt>
              <IntroParagraph>{intro}</IntroParagraph>
            </Intro>
          </Header>

          {sections.map((_, i) => (
            <ExpertStudySection title={_.title} content={_.content} index={i} />
          ))}
        </StudyContainer>
      </BlueBackground>

      <SliderSection>
        <Container fluidRight>
          <SectionHeader gray>
            <FormattedMessage id="home.expertsSection.header" />
          </SectionHeader>

          <StyledSlick {...slickSettings}>
            {messages[locale].home.expertsSection.items.map(_ => (
              <SlickItem
                tabIndex={0}
                onClick={() => {
                  onChange(_);
                  setTimeout(
                    () =>
                      document.getElementById('expert-start').scrollIntoView(),
                    16
                  );
                }}
              >
                <QuotePictures
                  action
                  vertical
                  images={_.authors.map(a => (
                    <ExpertPhotoProvider name={a} />
                  ))}
                />
                <Author style={{ maxWidth: 'none', fontSize: '2rem' }}>
                  {_.authors[0]}
                  {_.authors[1] && (
                    <>
                      ,<br />
                      {_.authors[1]}
                    </>
                  )}
                </Author>
                <Company style={{ color: colors.blue }}>{_.company}</Company>
                <Paragraph>{truncate(_.excerpt, 120)}</Paragraph>
              </SlickItem>
            ))}
          </StyledSlick>
        </Container>
      </SliderSection>

      <Footer onRedirect={close} />
    </Popup>
  );
};

export default ExpertStudyPopupSection;
