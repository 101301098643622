import React from 'react';
import styled from 'styled-components';
import InfoWithIcon from '../atoms/InfoWithIcon';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderSmallGray,
  infoHeadersTransformations,
  InfoHeaderBlack,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';
import UnlockedIcon from '../images/UnlockedIcon';
import KeyFinding2Image from '../images/KeyFinding2Image';
import DesktopInfoImageWrapper from './DesktopInfoImageWrapper';

const StyledInfoWithIcon = styled(InfoWithIcon)`
  max-width: 34rem;
  width: 100%;

  @media ${breakpoints.lg} {
    margin-top: 15rem;
  }

  @media ${breakpoints.xxl} {
    margin-top: 23rem;
  }
`;

const Header = styled.header`
  & > ${InfoHeaderSmallGray} {
    margin-left: 1rem;
  }

  & > ${InfoHeaderBlack} {
    display: block;
    margin-top: 1rem;
  }
`;

const Info4 = ({ className }) => (
  <>
    <DesktopInfoImageWrapper className={className}>
      <KeyFinding2Image />
    </DesktopInfoImageWrapper>

    <StyledInfoWithIcon
      className={className}
      icon={<UnlockedIcon />}
      header={
        <Header>
          <FormattedMessage
            id="home.informationSection.items.3.header"
            values={infoHeadersTransformations}
          />
        </Header>
      }
    >
      <FormattedHTMLMessage id="home.informationSection.items.3.text" />
    </StyledInfoWithIcon>
  </>
);

export default Info4;
