import colors from '../../utils/colors';
import React from 'react';
import { PKOH1, PKOH4 } from './Headers';
import styled, { css } from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import { transparentize } from 'polished';
import NavRocketShape from '../images/NavRocketShape';

const StyledHeader = styled.header`
  position: relative;
  display: grid;
  grid-template-areas:
    'a'
    'b'
    'c';
  margin-bottom: 1.5rem;

  @media ${breakpoints.md} {
    ${({ horizontal }) =>
      horizontal &&
      css`
        grid-template-areas:
          'a c'
          'b b';
        grid-template-columns: 1fr 3fr;
        grid-column-gap: 5rem;
        align-items: center;
      `};
  }

  @media ${breakpoints.lg} {
    margin-bottom: 2rem;
    padding-left: ${({ indent }) => (indent ? '6rem' : 0)};
  }
`;

const StyledPKOH1 = styled(PKOH1)`
  grid-area: a;
  color: ${colors.blue};

  ${({ gray }) =>
    gray &&
    css`
      color: ${colors.grayLight};
    `};
`;

const StyledPKOH4 = styled(PKOH4)`
  grid-area: c;

  @media ${breakpoints.md} {
    color: ${colors.gray};
  }
`;

const Hr = styled.hr`
  grid-area: b;
  border: 0;
  height: 1px;
  width: 100%;
  background: linear-gradient(
    to right,
    ${colors.grayLight},
    ${transparentize(1, colors.grayLight)} 50%
  );
`;

const StyledRocketShape = styled(NavRocketShape)`
  display: none;

  @media screen and (min-width: 1140px) {
    position: absolute;
    top: 1.5rem;
    transform: translateX(-50%);
    z-index: 20;
    display: block;
    left: -50px;
  }

  @media ${breakpoints.xl} {
    left: -70px;
  }

  @media only screen and (min-width: 1440px) {
    left: -90px;
  }
`;

export const SectionHeader = ({
  indent,
  horizontal,
  subtitle,
  children,
  rocketId,
  gray,
  hr = true,
  ...props
}) => (
  <StyledHeader indent={indent} horizontal={horizontal} gray={gray} {...props}>
    <StyledPKOH1 gray={gray}>{children}</StyledPKOH1>
    {hr && <Hr />}
    {subtitle && <StyledPKOH4>{subtitle}</StyledPKOH4>}
    {rocketId && <StyledRocketShape id={rocketId} />}
  </StyledHeader>
);
