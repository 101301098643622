import React from 'react';
import styled from 'styled-components';
import InfoCircle from '../atoms/InfoCircle';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderBlack,
  InfoHeaderLargeBlue,
  InfoHeaderSmallGray,
  infoHeadersTransformations,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';
import MobileInfoImageWrapper from './MobileInfoImageWrapper';
import KeyFinding5Image from '../images/KeyFinding5Image';

const StyledInfoCircle = styled(InfoCircle)`
  margin-top: 4.5rem;
  padding-bottom: 1rem;

  @media ${breakpoints.lg} {
    margin-top: 5rem;
  }

  @media ${breakpoints.xxl} {
    margin-top: 11rem;
  }
`;

const Header = styled.header`
  & > ${InfoHeaderBlack} {
    display: block;
    margin-right: 7.5rem;
  }

  & > ${InfoHeaderLargeBlue} {
    line-height: 0.7;
  }

  & > ${InfoHeaderSmallGray} {
    margin-left: 1rem;
  }

  @media ${breakpoints.lg} {
    display: grid;
    grid-template-areas: 'a b' 'c b';
    grid-template-rows: 2fr 1fr;
    justify-content: center;
    grid-column-gap: 1.5rem;
    margin-bottom: 1rem;

    & > ${InfoHeaderLargeBlue} {
      grid-area: b;
    }

    & > ${InfoHeaderSmallGray} {
      justify-self: flex-end;
      align-self: center;
      margin-left: 0;
      line-height: 0.6;
    }

    & > ${InfoHeaderBlack} {
      margin-right: 0;
      justify-self: flex-end;
      align-self: flex-end;
    }
  }
`;

const StyledMobileInfoImageWrapper = styled(MobileInfoImageWrapper)`
  top: -6rem;
  right: 0;
  width: 13rem;
  z-index: 3;

  @media ${breakpoints.md} {
    width: 22rem;
    top: -7rem;
  }

  & > .gatsby-image-wrapper {
    right: -4rem;
  }
`;

const Info8 = () => (
  <>
    <StyledMobileInfoImageWrapper>
      <KeyFinding5Image />
    </StyledMobileInfoImageWrapper>

    <StyledInfoCircle
      header={
        <Header>
          <FormattedMessage
            id="home.informationSection.items.7.header"
            values={infoHeadersTransformations}
          />
        </Header>
      }
    >
      <FormattedHTMLMessage id="home.informationSection.items.7.text" />
    </StyledInfoCircle>
  </>
);

export default Info8;
