import React from 'react';
import styled from 'styled-components';
import InfoWithIcon from '../atoms/InfoWithIcon';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderLargeBlue,
  InfoHeaderSmallGray,
  infoHeadersTransformations,
  InfoHeaderBlack,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';
import KeyIcon from '../images/KeyIcon';
import KeyFinding5Image from '../images/KeyFinding5Image';
import DesktopInfoImageWrapper from './DesktopInfoImageWrapper';

const StyledInfoWithIcon = styled(InfoWithIcon)`
  @media ${breakpoints.lg} {
    margin-left: 3rem;
    padding-top: 18rem;
    width: 48rem;
  }

  @media ${breakpoints.xxl} {
    padding-top: 24rem;
  }
`;

const Header = styled.header`
  & > ${InfoHeaderLargeBlue} {
    display: block;
  }

  & > ${InfoHeaderSmallGray} {
    display: block;
    font-size: 3.125rem;
  }

  & > ${InfoHeaderBlack} {
    display: block;
    margin-top: 0.5rem;
    font-size: 2.5rem;
  }

  @media ${breakpoints.lg} {
    display: grid;
    grid-template-areas: 'a b' 'a c';
    grid-template-rows: 1.5fr 1fr;
    align-items: flex-end;
    justify-content: flex-start;
    grid-column-gap: 1.5rem;

    & > ${InfoHeaderLargeBlue} {
      grid-area: a;
      width: 6.625rem;
      line-height: 0.9;
    }

    & > ${InfoHeaderSmallGray} {
      grid-area: b;
    }

    & > ${InfoHeaderBlack} {
      font-size: 3.4375rem;
      grid-area: c;
      margin-top: 0;
    }
  }

  @media ${breakpoints.xl} {
    grid-column-gap: 3rem;
  }
`;

const Info9 = () => (
  <>
    <DesktopInfoImageWrapper>
      <KeyFinding5Image />
    </DesktopInfoImageWrapper>

    <StyledInfoWithIcon
      iconOffset={20}
      icon={<KeyIcon />}
      header={
        <Header>
          <FormattedMessage
            id="home.informationSection.items.8.header"
            values={infoHeadersTransformations}
          />
        </Header>
      }
    >
      <FormattedHTMLMessage id="home.informationSection.items.8.text" />
    </StyledInfoWithIcon>
  </>
);

export default Info9;
