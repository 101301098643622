import React from 'react';
import startupTheBank from '../../images/startup-the-bank.svg';

const StartupTheBank = props => {
  return (
    <img src={startupTheBank} alt="StartUp The Bank Rocket" {...props} />
  );
};

export default StartupTheBank;
