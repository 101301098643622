import styled, { css } from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import colors from '../../utils/colors';
import SlickDot from './SlickDot';

const SlickDotsWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
  width: 90%;

  & > .slick-active > ${SlickDot} {
    background-color: ${colors.red};
  }

  @media ${breakpoints.md} {
    height: 2rem;
    width: auto;
    flex-wrap: nowrap;
    justify-content: flex-end;
    height: 3.75rem;
    margin: 0 17rem 0 0;

    ${({ progressbar }) =>
      progressbar &&
      css`
        margin-left: 1rem;
        background-color: ${colors.grayLight};
        height: 0.5rem;
        border-radius: 0.5rem;
        margin-bottom: 1.687rem;
        justify-content: space-between;

        & > li {
          flex-grow: 1;
          height: 100%;
        }

        & > li:first-child > ${SlickDot} {
          border-bottom-left-radius: 0.875rem;
          border-top-left-radius: 0.875rem;
        }

        & > .slick-active > ${SlickDot} {
          background-color: ${colors.blue};
          border-bottom-right-radius: 0.875rem;
          border-top-right-radius: 0.875rem;
        }

        & > .slick-active ~ li > ${SlickDot} {
          background-color: ${colors.grayLight};
          border-bottom-right-radius: 0.875rem;
          border-top-right-radius: 0.875rem;
        }
      `};
  }

  ${({ overflow }) =>
    overflow &&
    css`
      @media ${breakpoints.lg} {
        margin-right: 22rem;
      }

      @media ${breakpoints.xl} {
        margin-right: 27rem;
      }

      @media ${breakpoints.desktop} {
        margin-right: 37rem;
      }
    `};
`;

export default SlickDotsWrapper;
