import React from 'react';
import wishes4 from '../../images/wishes-4.svg';

const Wishes4Shape = props => {
  return (
    <img src={wishes4} alt="Wishes4Shape" {...props} />
  );
};

export default Wishes4Shape;
