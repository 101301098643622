import styled, { css } from 'styled-components';
import colors from '../../utils/colors';

const IconCircle = styled.div`
  width: 4.375rem;
  height: 4.375rem;
  background-color: ${colors.red};
  color: white;
  font-size: 1.875rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ small }) =>
    small &&
    css`
      width: 2.25rem;
      height: 2.25rem;
      font-size: 1rem;
    `}
`;

export default IconCircle;
