import React from 'react';
import styled, { css } from 'styled-components';
import { FaQuoteRight, FaPlus } from 'react-icons/all';
import colors from '../../utils/colors';
import breakpoints from '../../utils/breakpoints';
import IconCircle from '../atoms/IconCircle';
import { transparentize } from 'polished';

const QuotePicturesContainer = styled.div`
  position: relative;
  width: ${({ size }) => (size === 2 ? '16rem' : '9rem')};
  height: 9rem;

  ${({ large, size }) =>
    large &&
    css`
      width: ${size === 2 ? '17.5rem' : '12.8125rem'};
      height: ${size === 2 ? '10rem' : '12.8125rem'};
    `};

  ${({ vertical, size }) =>
    vertical
      ? css`
          @media ${breakpoints.md} {
            width: ${size === 2 ? '11.25rem' : '6.25rem'};
            height: 6.25rem;
          }
        `
      : css`
          height: ${size === 2 ? '14rem' : '10rem'};

          @media ${breakpoints.md} {
            width: ${size === 2 ? '17.5rem' : '12.8125rem'};
            height: ${size === 2 ? '21.875rem' : '12.8125rem'};
          }
        `};
`;

const QuoteIcon = styled(FaQuoteRight)`
  position: absolute;
  ${({ vertical }) =>
    vertical
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `};
  left: 0;
  color: ${colors.blue};
  z-index: 3;
  font-size: 2.25rem;

  @media ${breakpoints.lg} {
    font-size: ${({ vertical }) => (vertical ? '2.25rem' : '3.75rem')};
  }

  ${({ large }) =>
    large &&
    css`
      color: #ffc331;
      font-size: 3.25rem;
      bottom: 0;
      top: auto;
    `};
`;

export const QuotePictureWrapper = styled.div`
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  width: 9rem;
  height: 9rem;

  @media ${breakpoints.md} {
    ${({ large, size }) =>
      large &&
      css`
        width: ${size === 2 ? '10rem' : '12.8125rem'};
        height: ${size === 2 ? '10rem' : '12.8125rem'};
      `};
  }

  ${({ vertical }) =>
    vertical
      ? css`
          @media ${breakpoints.md} {
            width: 6.25rem;
            height: 6.25rem;
          }
        `
      : css`
          @media ${breakpoints.lg} {
            width: 12.8125rem;
            height: 12.8125rem;
          }
        `};

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
  }
`;

const FirstPictureWrapper = styled(QuotePictureWrapper)`
  top: 0;
  left: 0;
  z-index: 1;
`;

const SecondPictureWrapper = styled(QuotePictureWrapper)`
  bottom: 0;
  right: 0;
  z-index: 2;
`;

const StyledIconCircle = styled(IconCircle)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 4;
`;

export const Overlay = styled.div`
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${transparentize(0.6, '#004A99')};
  mix-blend-mode: overlay;
`;

const QuotePictures = ({
  vertical,
  images,
  action,
  large,
  overlay,
  ...props
}) => (
  <QuotePicturesContainer
    vertical={vertical}
    size={images.length}
    large={large}
    {...props}
  >
    <QuoteIcon vertical={vertical} large={large} />
    <FirstPictureWrapper vertical={vertical} large={large} size={images.length}>
      {images[0]}
      {overlay && <Overlay />}
    </FirstPictureWrapper>
    {images[1] && (
      <SecondPictureWrapper
        vertical={vertical}
        large={large}
        size={images.length}
      >
        {images[1]}
        {overlay && <Overlay />}
      </SecondPictureWrapper>
    )}
    {action && (
      <StyledIconCircle small>
        <FaPlus />
      </StyledIconCircle>
    )}
  </QuotePicturesContainer>
);

export default QuotePictures;
