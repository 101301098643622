import React from 'react';
import wishes3 from '../../images/wishes-3.svg';

const Wishes3Shape = props => {
  return (
    <img src={wishes3} alt="Wishes3Shape" {...props} />
  );
};

export default Wishes3Shape;
