import React from 'react';
import cashFlowIcon from '../../images/cash-flow-icon.svg';

const CashFlowIcon = props => {
  return (
    <img src={cashFlowIcon} alt="CashFlowIcon" {...props} />
  );
};

export default CashFlowIcon;
