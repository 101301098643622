import styled from 'styled-components';
import fonts from '../../utils/fonts';
import colors from '../../utils/colors';
import React, { useState } from 'react';
import breakpoints from '../../utils/breakpoints';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export const InfoHeaderLargeBlue = styled.span`
  font-size: 9rem;
  font-family: ${fonts.barlowCondensed};
  font-weight: 500;
  line-height: 1;
  color: ${colors.blue};

  @media ${breakpoints.xl} {
    font-size: 13.125rem;
  }
`;

export const InfoHeaderLargeGray = styled.span`
  font-size: 9.625rem;
  font-family: ${fonts.barlowCondensed};
  font-weight: 500;
  line-height: 1;
  color: ${colors.grayLight};

  @media ${breakpoints.xl} {
    font-size: 12rem;
  }
`;

export const InfoHeaderSmallGray = styled.span`
  font-size: 3.4375rem;
  font-family: ${fonts.barlowCondensed};
  font-weight: 500;
  line-height: 1;
  color: ${colors.grayLight};

  @media ${breakpoints.xl} {
    font-size: 4.375rem;
  }
`;

export const InfoHeaderBlack = styled.span`
  font-size: 3.4375rem;
  font-family: ${fonts.pko};
  font-weight: 700;
  line-height: 1;
  color: ${colors.black};

  @media ${breakpoints.xl} {
    font-size: 4.375rem;
  }
`;

const CountUpHeader = ({ value }) => {
  const [started, setStarted] = useState(false);

  return (
    <CountUp start={started ? null : 0} end={value} useEasing={false}>
      {({ countUpRef }) => (
        <VisibilitySensor
          partialVisibility
          onChange={isVisible => {
            if (isVisible) {
              setStarted(true);
            }
          }}
          delayedCall
        >
          <span ref={countUpRef} />
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

export const infoHeadersTransformations = {
  hlgblue: str => (
    <InfoHeaderLargeBlue>
      {!isNaN(Number(str)) ? <CountUpHeader value={Number(str)} /> : str}
    </InfoHeaderLargeBlue>
  ),
  hlggray: str => <InfoHeaderLargeGray>{str}</InfoHeaderLargeGray>,
  hsmgray: str => <InfoHeaderSmallGray>{str}</InfoHeaderSmallGray>,
  hblack: str => <InfoHeaderBlack>{str}</InfoHeaderBlack>,
};
