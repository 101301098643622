import React from 'react';
import styled from 'styled-components';
import InfoWithIcon from '../atoms/InfoWithIcon';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderLargeBlue,
  infoHeadersTransformations,
  InfoHeaderBlack,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';
import AppInteractionIcon from '../images/AppInteractionIcon';
import KeyFinding3Image from '../images/KeyFinding3Image';
import DesktopInfoImageWrapper from './DesktopInfoImageWrapper';

const StyledInfoWithIcon = styled(InfoWithIcon)`
  @media ${breakpoints.lg} {
    width: 37.5rem;
    padding-top: 17rem;
  }

  @media ${breakpoints.xxl} {
    padding-top: 24rem;
  }
`;

const Header = styled.header`
  & > ${InfoHeaderLargeBlue} {
    display: block;
    margin-bottom: 1rem;
  }

  @media ${breakpoints.md} {
    display: flex;
    align-items: flex-end;

    & > ${InfoHeaderLargeBlue} {
      width: 6.625rem;
      display: inline-block;
      margin-right: 3.125rem;
      text-align: center;
      line-height: 0.9;
      margin-bottom: 0;
    }

    & > ${InfoHeaderBlack} {
      display: block;
      max-width: 18.75rem;
    }
  }
`;

const Info5 = () => (
  <>
    <DesktopInfoImageWrapper>
      <KeyFinding3Image />
    </DesktopInfoImageWrapper>

    <StyledInfoWithIcon
      iconOffset={30}
      icon={<AppInteractionIcon />}
      header={
        <Header>
          <FormattedMessage
            id="home.informationSection.items.4.header"
            values={infoHeadersTransformations}
          />
        </Header>
      }
    >
      <FormattedHTMLMessage id="home.informationSection.items.4.text" />
    </StyledInfoWithIcon>
  </>
);

export default Info5;
