import React from 'react';
import withLayout from '../components/withLayout';
import WelcomeSection from '../components/sections/WelcomeSection';
import InformationIntroSection from '../components/sections/InformationIntroSection';
import ReportAuthorsSection from '../components/sections/ReportAuthorsSection';
import ExpertStudiesSection from '../components/sections/ExpertStudiesSection';
import StartupDialogsSection from '../components/sections/StartupDialogsSection';
import StartupWishesSection from '../components/sections/StartupWishesSection';
import FounderTipsSection from '../components/sections/FounderTipsSection';
import MeetTheStartupsSection from '../components/sections/MeetTheStartupsSection';
import InformationSliderSection from '../components/sections/InformationSliderSection';
import NavigationLine from '../components/organisms/NavigationLine';
import { isIE } from 'react-device-detect';

const IndexPage = () => {
  return (
    <>
      <WelcomeSection />
      <InformationIntroSection />
      <InformationSliderSection />
      <ExpertStudiesSection />
      <StartupDialogsSection />
      <StartupWishesSection />
      <FounderTipsSection />
      <MeetTheStartupsSection />
      <ReportAuthorsSection />
      {!isIE && <NavigationLine />}
    </>
  );
};

export default withLayout(IndexPage);
