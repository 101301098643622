import styled from 'styled-components';
import fonts from '../../utils/fonts';

const ExpertStudyExcerpt = styled.div`
  font-family: ${fonts.barlowCondensed};
  font-weight: 600;
  font-size: 1.5625rem;
  margin-bottom: 1.875rem;
  line-height: 1.25;
`;

export default ExpertStudyExcerpt;
