import React from 'react';
import wishes9 from '../../images/wishes-9.svg';

const Wishes9Shape = props => {
  return (
    <img src={wishes9} alt="Wishes9Shape" {...props} />
  );
};

export default Wishes9Shape;
