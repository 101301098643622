import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const KeyFinding4Image = props => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "key-finding-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 480, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return <Img {...props} fluid={data.placeholderImage.childImageSharp.fluid} />;
};

export default KeyFinding4Image;
