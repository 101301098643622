import React, { useState } from 'react';
import styled from 'styled-components';
import Paragraph from '../atoms/Paragraph';
import truncate from '../../utils/truncate';
import fonts from '../../utils/fonts';
import colors from '../../utils/colors';
import { lighten, darken } from 'polished';

const Button = styled.button`
  border: 0;
  background: 0;
  font-family: ${fonts.barlow};
  color: ${colors.red};
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.25rem 0;

  &:hover {
    color: ${lighten(0.15, colors.red)};
  }

  &:active {
    color: ${darken(0.15, colors.red)};
  }
`;

const ExpandableParagraph = ({ text, ...props }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Paragraph
        {...props}
        dangerouslySetInnerHTML={{
          __html: expanded ? text : truncate(text, 200),
        }}
      />
      {!expanded && (
        <Button onClick={() => setExpanded(true)}>więcej...</Button>
      )}
    </>
  );
};

export default ExpandableParagraph;
