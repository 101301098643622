import styled from 'styled-components';
import React from 'react';
import Particles from 'react-particles-js';
import breakpoints from '../../utils/breakpoints';

const StyledParticles = styled(Particles)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh + 20rem);
  overflow: hidden;

  @media ${breakpoints.lg} {
    height: calc(100vh + 2rem);
  }
`;

const FloatingCircles = () => (
  <StyledParticles
    params={{
      particles: {
        number: { value: 2, density: { enable: true, value_area: 200 } },
        color: {
          value: [
            '#14ADE3',
            '#6BBF28',
            '#FFC331',
            '#F3387A',
            '#F8671E',
            '#5bc0a7',
          ],
        },
        shape: {
          type: 'circle',
          stroke: { width: 0 },
          polygon: { nb_sides: 5 },
        },
        opacity: {
          value: 1,
          random: false,
          anim: { enable: false },
        },
        size: {
          value: 50,
          random: true,
          anim: { enable: false },
        },
        line_linked: {
          enable: false,
        },
        move: {
          enable: true,
          speed: 6,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: { enable: true, rotateX: 800, rotateY: 1400 },
        },
      },
      retina_detect: true,
    }}
  />
);

export default FloatingCircles;
