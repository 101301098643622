import styled, { css } from 'styled-components';
import colors from '../../utils/colors';
import breakpoints from '../../utils/breakpoints';

const SlickDot = styled.div.attrs({ tabIndex: 0 })`
  display: block;
  border: 2px solid ${colors.red};
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  margin: 0.425rem;
  transition: background-color 400ms;
  cursor: pointer;

  @media ${breakpoints.md} {
    width: 1.5625rem;
    height: 1.5625rem;
    margin: 0 0.5rem;

    ${({ progressbar }) =>
      progressbar &&
      css`
        transition: none;
        background-color: ${colors.blue};
        height: 0.875rem;
        border-radius: 0;
        width: 100%;
        margin: 0;
        height: 100%;
        border: 0;
      `};
  }
`;

export default SlickDot;
