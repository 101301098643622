import React from 'react';
import styled from 'styled-components';
import InfoWithIcon from '../atoms/InfoWithIcon';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderSmallGray,
  InfoHeaderBlack,
  infoHeadersTransformations,
  InfoHeaderLargeBlue,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';
import HandshakeIcon from '../images/HandshakeIcon';

const StyledInfoWithIcon = styled(InfoWithIcon)`
  max-width: 30rem;

  @media ${breakpoints.xl} {
    max-width: 34rem;
  }
`;

const Header = styled.header`
  display: grid;
  grid-template-areas: 'a b' 'a c';
  align-items: flex-end;
  justify-content: center;
  grid-column-gap: 1rem;

  & > ${InfoHeaderLargeBlue} {
    grid-area: a;
    line-height: 0.8;
  }

  & > ${InfoHeaderSmallGray} {
    grid-area: b;
  }

  & > ${InfoHeaderBlack} {
    grid-area: c;
  }

  @media ${breakpoints.md} {
    grid-template-areas: 'b x a c';
    align-items: flex-end;
    justify-content: flex-start;
    grid-template-rows: auto;
    grid-template-columns: 6rem 0 auto auto;

    & > ${InfoHeaderSmallGray} {
      text-align: center;
    }
  }
`;

const Info2 = ({ ...props }) => (
  <StyledInfoWithIcon
    iconOffset={-50}
    {...props}
    icon={<HandshakeIcon />}
    header={
      <Header>
        <FormattedMessage
          id="home.informationSection.items.1.header"
          values={infoHeadersTransformations}
        />
      </Header>
    }
  >
    <FormattedHTMLMessage id="home.informationSection.items.1.text" />
  </StyledInfoWithIcon>
);

export default Info2;
