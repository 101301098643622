import React from 'react';
import wishes5 from '../../images/wishes-5.svg';

const Wishes5Shape = props => {
  return (
    <img src={wishes5} alt="Wishes5Shape" {...props} />
  );
};

export default Wishes5Shape;
