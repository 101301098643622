import React from 'react';
import styled from 'styled-components';
import InfoCircle from '../atoms/InfoCircle';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderBlack,
  InfoHeaderLargeBlue,
  infoHeadersTransformations,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';
import KeyFinding3Image from '../images/KeyFinding3Image';
import MobileInfoImageWrapper from './MobileInfoImageWrapper';

const StyledInfoCircle = styled(InfoCircle)`
  padding-bottom: 2rem;
  margin-top: 3rem;

  @media ${breakpoints.xxl} {
    margin-top: 8rem;
  }
`;

const Header = styled.header`
  & > ${InfoHeaderLargeBlue} {
    margin-left: 2.5rem;
  }

  & > ${InfoHeaderBlack} {
    display: block;
    margin-top: 0.5rem;
  }
`;

const StyledMobileInfoImageWrapper = styled(MobileInfoImageWrapper)`
  top: -6rem;
  left: 0;
  width: 14rem;
  z-index: 3;

  @media ${breakpoints.md} {
    width: 20rem;
  }

  & > .gatsby-image-wrapper {
    left: -3rem;
  }
`;

const Info6 = () => (
  <>
    <StyledMobileInfoImageWrapper>
      <KeyFinding3Image />
    </StyledMobileInfoImageWrapper>

    <StyledInfoCircle
      header={
        <Header>
          <FormattedMessage
            id="home.informationSection.items.5.header"
            values={infoHeadersTransformations}
          />
        </Header>
      }
    >
      <FormattedHTMLMessage id="home.informationSection.items.5.text" />
    </StyledInfoCircle>
  </>
);

export default Info6;
