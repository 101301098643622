import React, { createElement, useEffect, useState } from 'react';
import Container from '../atoms/Container';
import { SectionHeader } from '../atoms/SectionHeader';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import Section from '../atoms/Section';
import styled from 'styled-components';
import colors from '../../utils/colors';
import TextCard, { styleTextCardShape } from '../organisms/TextCard';
import Paragraph from '../atoms/Paragraph';
import 'slick-carousel/slick/slick.css';
import breakpoints from '../../utils/breakpoints';
import Slick from '../organisms/Slick';
import range from '../../utils/range';
import Wishes0Shape from '../images/Wishes0Shape';
import Wishes1Shape from '../images/Wishes1Shape';
import Wishes2Shape from '../images/Wishes2Shape';
import Wishes3Shape from '../images/Wishes3Shape';
import Wishes4Shape from '../images/Wishes4Shape';
import Wishes5Shape from '../images/Wishes5Shape';
import Wishes6Shape from '../images/Wishes6Shape';
import Wishes7Shape from '../images/Wishes7Shape';
import Wishes8Shape from '../images/Wishes8Shape';
import Wishes9Shape from '../images/Wishes9Shape';

const StyledSection = styled(Section)`
  background-color: ${colors.grayLighter};
`;

const StyledSlick = styled(Slick)`
  margin-top: -2.5rem;

  @media ${breakpoints.md} {
    margin-top: -3.5rem;
  }
`;

const SlickItem = styled.div`
  padding: 2.5rem 1rem;
  text-align: center;

  @media ${breakpoints.md} {
    padding: 4rem 1rem;
  }
`;

const wishesIconsList = [
  Wishes0Shape,
  Wishes1Shape,
  Wishes2Shape,
  Wishes3Shape,
  Wishes4Shape,
  Wishes5Shape,
  Wishes6Shape,
  Wishes7Shape,
  Wishes8Shape,
  Wishes9Shape,
].map(styleTextCardShape);

const StartupWishesSection = () => {
  const [showSlider, setShowSlider] = useState(false);

  useEffect(() => {
    setShowSlider(true);
  }, []);

  return (
    <StyledSection
      id={useIntl().formatMessage({ id: 'home.startupWishesSection.id' })}
    >
      <Container>
        <SectionHeader
          horizontal
          rocketId="nav-rocket-5"
          subtitle={
            <FormattedHTMLMessage id="home.startupWishesSection.header.subtitle" />
          }
        >
          <FormattedHTMLMessage id="home.startupWishesSection.header.main" />
        </SectionHeader>
      </Container>

      <Container noGutter fluidRight>
        {showSlider && (
          <StyledSlick progressbar overflow>
            {range(0, 9).map(i => (
              <SlickItem key={i}>
                <TextCard
                  title={
                    <FormattedHTMLMessage
                      id={`home.startupWishesSection.items.${i}.title`}
                    />
                  }
                  number={i + 1}
                  shape={createElement(wishesIconsList[i])}
                >
                  <Paragraph>
                    <FormattedHTMLMessage
                      id={`home.startupWishesSection.items.${i}.text`}
                    />
                  </Paragraph>
                </TextCard>
              </SlickItem>
            ))}
          </StyledSlick>
        )}
      </Container>
    </StyledSection>
  );
};

export default StartupWishesSection;
