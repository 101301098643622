import styled from 'styled-components';
import React, { useState } from 'react';
import colors from '../../utils/colors';
import Container from '../atoms/Container';
import { SectionHeader } from '../atoms/SectionHeader';
import LogoPKOFintech from '../images/LogoPKOFintech';
import LogoPKOTFI from '../images/LogoPKOTFI';
import LogoChmuraKrajowa from '../images/LogoChmuraKrajowa';
import LogoTheHeart from '../images/LogoTheHeart';
import Section from '../atoms/Section';
import breakpoints from '../../utils/breakpoints';
import { FormattedMessage, useIntl } from 'react-intl';
import ReportAuthorsPopupSection from './ReportAuthorsPopupSection';
import fintechPolandLogo from '../../images/fintech-poland-logo.png';

const Logos = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  margin: -1.25rem 0;
  flex-wrap: wrap;

  @media ${breakpoints.md} {
    margin: -1rem -1.5rem;
    justify-content: flex-start;
  }

  @media ${breakpoints.lg} {
    margin: -1rem;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

const LogoPKOFintechContainer = styled.div`
  margin: 1.25rem 0.425rem;
  flex: 0 0 70%;

  @media ${breakpoints.md} {
    margin: 1rem 2rem;
    flex: 0 0 32%;
  }

  @media ${breakpoints.lg} {
    margin: 1rem;
    flex: 0 0 21%;
  }

  @media ${breakpoints.xl} {
    flex: 0 0 18%;
  }
`;

const LogoPKOTFIContainer = styled.div`
  margin: 1.25rem 0.425rem 2rem;
  flex: 0 0 24%;

  @media ${breakpoints.md} {
    margin: 1rem 2rem;
    flex: 0 0 12%;
  }

  @media ${breakpoints.lg} {
    margin: 1rem;
    flex: 0 0 7.5%;
  }

  @media ${breakpoints.xl} {
    flex: 0 0 7%;
  }
`;

const LogoChmuraKrajowaContainer = styled.div`
  margin: 1.25rem 0.425rem 2rem;
  flex: 0 0 52%;

  @media ${breakpoints.md} {
    margin: 1rem 2rem;
    flex: 0 0 26%;
  }

  @media ${breakpoints.lg} {
    margin: 1rem;
    flex: 0 0 17%;
  }

  @media ${breakpoints.xl} {
    flex: 0 0 16%;
  }
`;

const LogoTheHeartContainer = styled.div`
  margin: 1.25rem 0.425rem;
  flex: 0 0 41%;

  @media ${breakpoints.md} {
    margin: 1rem 2rem;
    flex: 0 0 25%;
  }

  @media ${breakpoints.lg} {
    margin: 1rem;
    flex: 0 0 14%;
  }

  @media ${breakpoints.xl} {
    flex: 0 0 13%;
  }
`;

const FintechPolandLogoContainer = styled.div`
  margin: 1.25rem 0.425rem;
  flex: 0 0 42%;

  @media ${breakpoints.md} {
    margin: 1rem 2rem;
    flex: 0 0 22%;
  }

  @media ${breakpoints.lg} {
    margin: 1rem;
    flex: 0 0 14%;
  }

  @media ${breakpoints.lg} {
    flex: 0 0 12%;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const ReportAuthorsSection = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <>
      {popupOpen && (
        <ReportAuthorsPopupSection close={() => setPopupOpen(false)} />
      )}

      <Section
        id={useIntl().formatMessage({ id: 'home.reportAuthorsSection.id' })}
      >
        <Container
          tabIndex={0}
          onClick={() => setPopupOpen(true)}
          style={{ cursor: 'pointer' }}
        >
          <SectionHeader
            style={{ color: colors.grayLight }}
            rocketId="nav-rocket-8"
          >
            <FormattedMessage id="home.reportAuthorsSection.header" />
          </SectionHeader>

          <Logos>
            <LogoPKOFintechContainer>
              <LogoPKOFintech style={{ width: '100%', height: 'auto' }} />
            </LogoPKOFintechContainer>
            <LogoPKOTFIContainer>
              <LogoPKOTFI style={{ width: '100%', height: 'auto' }} />
            </LogoPKOTFIContainer>
            <LogoChmuraKrajowaContainer>
              <LogoChmuraKrajowa style={{ width: '100%', height: 'auto' }} />
            </LogoChmuraKrajowaContainer>
            <LogoTheHeartContainer>
              <LogoTheHeart style={{ width: '100%', height: 'auto' }} />
            </LogoTheHeartContainer>
            <FintechPolandLogoContainer>
              <ResponsiveImage src={fintechPolandLogo} />
            </FintechPolandLogoContainer>
          </Logos>
        </Container>
      </Section>
    </>
  );
};

export default ReportAuthorsSection;
