import React from 'react';
import navRocketShape from '../../images/nav-rocket-shape.svg';

const NavRocketShape = props => {
  return (
    <img src={navRocketShape} alt="NavRocketShape" {...props} />
  );
};

export default NavRocketShape;
