import styled from 'styled-components';
import { transparentize } from 'polished';
import colors from '../../utils/colors';

const GradientHr = styled.hr`
  width: 100%;
  border: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    ${transparentize(1, colors.blue)} 0%,
    rgba(255, 255, 255, 0.4) 50%,
    ${transparentize(1, colors.blue)} 100%
  );
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
`;

export default GradientHr;
