import React from 'react';
import tips2 from '../../images/tips-2.svg';

const Tips2Shape = props => {
  return (
    <img src={tips2} alt="Tips2Shape" {...props} />
  );
};

export default Tips2Shape;
