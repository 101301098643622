import React, { useEffect } from 'react';
import styled from 'styled-components';
import { containerStyles, largerContainerStyles } from '../../utils/styles';
import StartupTheBank from '../images/StartupTheBank';
import breakpoints from '../../utils/breakpoints';
import FloatingCircles from '../organisms/FloatingCircles';
import LinkBox from '../atoms/LinkBox';
import { PKOH2 } from '../atoms/Headers';
import { FormattedMessage, useIntl } from 'react-intl';
import { getRoute } from '../../utils/router';
import { FaArrowDown } from 'react-icons/all';

const StyledSection = styled.section`
  padding-bottom: 2.5rem;
  position: relative;
  min-height: 27.5rem;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 18.75rem;
  display: flex;
  align-items: center;
  position: relative;

  @media ${breakpoints.md} {
    height: 60vh;
    min-height: 26rem;
    max-height: 33.33rem;
  }
`;

const HideOverflow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  flex-grow: 1;
`;

const StyledStartupTheBank = styled(StartupTheBank)`
  height: calc(100% + 2rem);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 90%;
  bottom: -4rem;

  @media ${breakpoints.md} {
    bottom: -5rem;
  }
`;

const PeakSection = styled.div`
  ${containerStyles};
  position: relative;
  z-index: 8;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media ${breakpoints.md} {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
`;

const StyledLinkWrapper = styled(LinkBox)`
  flex-shrink: 0;
  margin-right: 1.5rem;

  width: 2.8125rem;
  height: 2.8125rem;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.md} {
    width: 3.75rem;
    height: 3.75rem;
    font-size: 1.875rem;
  }

  @media ${breakpoints.lg} {
    margin-right: 3.75rem;
  }
`;

const WelcomeSection = () => {
  useEffect(() => {
    const timeout = setTimeout(
      () => document.getElementById('welcome-video').play(),
      1000
    );

    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledSection id="intro">
      <VideoContainer>
        <HideOverflow>
          <StyledVideo id="welcome-video" autoPlay muted loop playsInline>
            <source src="/assets/intro-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </StyledVideo>
        </HideOverflow>
        <StyledStartupTheBank />
      </VideoContainer>
      <PeakSection>
        <StyledLinkWrapper to={getRoute('HomeResultsSection', useIntl())}>
          <FaArrowDown style={{ flexShrink: 0 }} />
        </StyledLinkWrapper>
        <PKOH2>
          <FormattedMessage id="home.welcomeSection.peak" />
        </PKOH2>
      </PeakSection>
      <FloatingCircles />
    </StyledSection>
  );
};

export default WelcomeSection;
