import React from 'react';
import styled from 'styled-components';
import { PKOH2 } from '../atoms/Headers';
import fonts from '../../utils/fonts';
import colors from '../../utils/colors';
import breakpoints from '../../utils/breakpoints';
import IconCircle from '../atoms/IconCircle';
import { FaPlus } from 'react-icons/fa';

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Subtitle = styled.h3`
  font-family: ${fonts.barlow};
  font-weight: 300;
  margin: 0.5rem 0;

  strong {
    font-weight: 500;
  }
`;

const Divider = styled.span`
  display: none;

  @media ${breakpoints.md} {
    display: inline;
    color: ${colors.gray};
    margin: 0 0.5rem;
  }
`;

const Author = styled.span`
  display: block;

  @media ${breakpoints.md} {
    display: inline;
    color: ${colors.gray};
  }
`;

const StyledIconCircle = styled(IconCircle)`
  flex-shrink: 0;

  @media ${breakpoints.toMd} {
    width: 3rem;
    height: 3rem;
    font-size: 1.25rem;
    align-self: flex-start;
    margin: 1rem 0 0 1rem;
  }

  @media ${breakpoints.md} {
    display: flex;
    margin-left: 1rem;
  }
`;

const ExpertStudyTitle = ({ children, authors, ...props }) => (
  <StyledHeader {...props}>
    <div>
      <PKOH2>{children}</PKOH2>
      <Subtitle>
        <Author>
          <strong>{authors[0].name}</strong> {authors[0].company}
        </Author>
        {authors[1] && (
          <>
            <Divider>/</Divider>
            <Author>
              <strong>{authors[1].name}</strong> {authors[1].company}
            </Author>
          </>
        )}
      </Subtitle>
    </div>
    <StyledIconCircle>
      <FaPlus />
    </StyledIconCircle>
  </StyledHeader>
);

export default ExpertStudyTitle;
