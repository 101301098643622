import React, { useState } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import { SectionHeader } from '../atoms/SectionHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import Section from '../atoms/Section';
import StartupDialogCard from '../organisms/StartupDialogCard';
import 'slick-carousel/slick/slick.css';
import breakpoints from '../../utils/breakpoints';
import Slick from '../organisms/Slick';
import DialogPopupSection from './DialogPopupSection';
import { messages } from '../../i18n/translations';
import truncate from '../../utils/truncate';

const StyledSlick = styled(Slick)`
  margin-top: -1rem;

  @media ${breakpoints.md} {
    margin-top: -2rem;
  }
`;

const SlickItem = styled.div`
  padding: 1.5rem 1rem;
  text-align: center;

  @media ${breakpoints.md} {
    padding: 2rem 1rem;
  }
`;

const StartupDialogsSection = () => {
  const [dialogProps, setDialogProps] = useState(null);
  const intl = useIntl();

  return (
    <>
      {dialogProps && (
        <DialogPopupSection
          {...dialogProps}
          onChange={e => setDialogProps({ ...e, logo: e.company })}
          close={() => setDialogProps(null)}
        />
      )}
      <Section id={intl.formatMessage({ id: 'home.startupDialogSection.id' })}>
        <Container>
          <SectionHeader indent rocketId="nav-rocket-4">
            <FormattedMessage id="home.startupDialogSection.header" />
          </SectionHeader>
        </Container>

        <Container noGutter fluidRight>
          <StyledSlick overflow>
            {messages[intl.locale].home.startupDialogSection.items.map(_ => (
              <SlickItem key={_.title}>
                <StartupDialogCard
                  onClick={() => setDialogProps({ ..._, logo: _.company })}
                  title={_.title}
                  question={_.questions[0].question}
                  answer={truncate(_.questions[0].answer, 140)}
                  author={_.author}
                  logo={_.company}
                />
              </SlickItem>
            ))}
          </StyledSlick>
        </Container>
      </Section>
    </>
  );
};

export default StartupDialogsSection;
