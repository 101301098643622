import QuotePictures from '../molecules/QuotePictures';
import ExpertStudyTitle from '../molecules/ExpertStudyTitle';
import React from 'react';
import styled from 'styled-components';
import fonts from '../../utils/fonts';
import breakpoints from '../../utils/breakpoints';
import { useMediaQuery } from 'react-responsive';
import truncate from '../../utils/truncate';

const StudyContainer = styled.section`
  display: grid;
  grid-template-areas:
    'a a a'
    'b b b'
    'c c c';
  cursor: pointer;

  @media ${breakpoints.md} {
    grid-template-areas:
      'b b b'
      'a c c';
    grid-column-gap: 2.5rem;
    grid-row-gap: 2rem;
  }

  @media ${breakpoints.lg} {
    grid-template-areas:
      'a b b'
      'a c c'
      'a c c';
    grid-column-gap: 3.75rem;
    grid-row-gap: 0;
  }
`;

const StyledQuotePictures = styled(QuotePictures)`
  grid-area: a;
`;

const StyledExpertStudyTitle = styled(ExpertStudyTitle)`
  grid-area: b;
`;

const HugeParagraph = styled.p`
  grid-area: c;
  font-family: ${fonts.pko};
  line-height: 1.35;
  font-size: 1.125rem;
  margin: 1rem 0;

  @media ${breakpoints.md} {
    line-height: 1.25;
    font-size: 1.625rem;
    margin: 1.25rem 0;
    align-self: center;
  }

  @media ${breakpoints.lg} {
    font-size: 1.875rem;
    margin: 1.5rem 0;
  }
`;

const ExpertStudy = ({
  images,
  title,
  authors,
  excerpt,
  onClick,
  index,
  ...props
}) => {
  const isMobile = useMediaQuery({ query: breakpoints.toMd });

  return (
    <StudyContainer {...props} onClick={onClick} tabIndex={0}>
      <StyledQuotePictures images={images} />
      <StyledExpertStudyTitle authors={authors}>
        {title}
      </StyledExpertStudyTitle>
      <HugeParagraph>
        {isMobile ? truncate(excerpt, 200) : excerpt}
      </HugeParagraph>
    </StudyContainer>
  );
};

export default ExpertStudy;
