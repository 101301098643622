import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import colors from '../../utils/colors';
import breakpoints from '../../utils/breakpoints';
import NavRocketShape from '../images/NavRocketShape';
import { transparentize } from 'polished';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'react-intl';
import { getRoute } from '../../utils/router';
import {
  acceleratedEasing,
  containerStyles,
  deceleratedEasing,
} from '../../utils/styles';
import range from '../../utils/range';

const minWidth = '1140px';

const BodyRelative = createGlobalStyle`
  @media screen and (min-width: ${minWidth}) {
    body {
      position: relative;
    }
  }
`;

const NavigationLineContainer = styled.div`
  display: none;

  @media screen and (min-width: ${minWidth}) {
    display: block;
    ${containerStyles};
    height: 0;
  }
`;

const AbsoluteContainer = styled.div`
  @media screen and (min-width: ${minWidth}) {
    display: block;
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: -50px;
  }

  @media screen and (min-width: ${minWidth}) {
    margin-left: -50px;
  }

  @media ${breakpoints.xl} {
    margin-left: -70px;
  }

  @media only screen and (min-width: 1440px) {
    margin-left: -90px;
  }
`;

const StickyLine = styled.div`
  position: absolute;
  top: 4.5rem;
  left: calc(50% - 1px);
  bottom: 0;
  width: 1px;
  background-color: ${transparentize(0.7, colors.gray)};
  z-index: 1;
`;

const StyledNavRocketShape = styled(NavRocketShape)`
  position: absolute;
  top: 2.5rem;
  transform: translateX(-50%);
  z-index: 15;

  @media screen and (min-width: ${minWidth}) {
    margin-left: -50px;
  }

  @media ${breakpoints.xl} {
    margin-left: -70px;
  }

  @media only screen and (min-width: 1440px) {
    margin-left: -90px;
  }
`;

const NavigationWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  z-index: 14;
  width: 2rem;
`;

const Navigation = styled.nav`
  position: sticky;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${minWidth}) {
    top: 25vh;
  }

  @media only screen and (min-width: 1366px) {
    top: 30vh;
  }
`;

const NavigationTooltip = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 100%;
  transform-origin: left center;
  transform: translate(0.5rem, -50%) scale(0.8);
  overflow: hidden;
  opacity: 0;
  transition: opacity 200ms ${acceleratedEasing};
`;

const NavigationItem = styled(Link)`
  display: inline-block;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: white;
  border: 1px solid ${colors.grayLight};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  position: relative;
  transition: border 200ms ${acceleratedEasing},
    transform 200ms ${acceleratedEasing};

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: ${colors.blue};
    opacity: 0;
    transition: opacity 200ms ${deceleratedEasing},
      transform 200ms ${deceleratedEasing};
    transform: scale(0);
  }

  ${({ active }) =>
    active &&
    css`
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    `}

  &:hover {
    border: 1px solid ${colors.red};
    transform: scale(1.25);

    ${NavigationTooltip} {
      width: auto;
      height: auto;
      opacity: 1;
      background-color: white;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
      color: ${colors.gray} !important;
      display: none;
      border-radius: 0.5rem;
      font-size: 14px;
      display: block;
      padding: 0.375rem 0.5rem;
      white-space: nowrap;
    }
  }
`;

const NavigationLine = () => {
  const intl = useIntl();
  const [active, setActive] = useState(0);

  useEffect(() => {
    const listener = () => {
      const line = document.getElementById('nav-line');

      const lineOffset = line ? line.getBoundingClientRect().bottom : 0;
      setActive(
        range(1, 8)
          .map(_ => `nav-rocket-${_}`)
          .map(_ => document.getElementById(_))
          .filter(_ => !!_)
          .filter(_ => _.getBoundingClientRect().top < lineOffset).length - 1
      );
    };

    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener);
  }, []);

  return (
    <>
      <BodyRelative />
      <NavigationLineContainer>
        <AbsoluteContainer>
          <StickyLine />
          <NavigationWrapper>
            <Navigation id="nav-line">
              {[
                'ComponentHome',
                'HomeResultsSection',
                'HomeExpertsSection',
                'HomeInterviewsSection',
                'HomeWishesSection',
                'HomeFounderSection',
                'HomeMeetStartupsSection',
                'HomeReportAuthorsSection',
              ].map((_, i) => (
                <NavigationItem
                  key={_}
                  active={i === active}
                  to={getRoute(_, intl)}
                >
                  <NavigationTooltip>
                    <FormattedMessage id={`home.nav.${i}`} />
                  </NavigationTooltip>
                </NavigationItem>
              ))}
            </Navigation>
          </NavigationWrapper>
        </AbsoluteContainer>
        <StyledNavRocketShape id="nav-rocket-1" />
      </NavigationLineContainer>
    </>
  );
};

export default NavigationLine;
