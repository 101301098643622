import React from 'react';
import handshakeIcon from '../../images/handshake-icon.svg';

const HandshakeIcon = props => {
  return (
    <img src={handshakeIcon} alt="HandshakeIcon" {...props} />
  );
};

export default HandshakeIcon;
