const truncate = (str, length) => {
  if (str.length <= length) {
    return str;
  }

  const maxLength = length - 3;
  let trimmedString = str.substr(0, maxLength);
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  );

  return `${trimmedString}...`;
};

export default truncate;
