import React from 'react';
import wishes1 from '../../images/wishes-1.svg';

const Wishes1Shape = props => {
  return (
    <img src={wishes1} alt="Wishes1Shape" {...props} />
  );
};

export default Wishes1Shape;
