import React from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';
import PropTypes from 'prop-types';
import Paragraph from './Paragraph';
import breakpoints from '../../utils/breakpoints';

const CircleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 22.5rem;

  @media ${breakpoints.md} {
    max-width: 26rem;
  }

  @media ${breakpoints.xl} {
    max-width: 27.5rem;
  }

  &::before {
    content: '';
    display: block;
    z-index: -1;
    width: 22.5rem;
    height: 22.5rem;
    border: 1px solid ${colors.grayLight};
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media ${breakpoints.md} {
      width: 26rem;
      height: 26rem;
    }

    @media ${breakpoints.xl} {
      width: 27.5rem;
      height: 27.5rem;
    }
  }
`;

const StyledParagraph = styled(Paragraph)`
  padding: 0.625rem 2.5rem 3rem;
  margin: 0;

  @media ${breakpoints.md} {
    padding: 0.625rem 2.75rem 4rem;
  }

  @media ${breakpoints.xl} {
    padding: 0.75rem 3.5rem 4rem;
  }
`;

const InfoCircle = ({ header, children, ...props }) => (
  <CircleWrapper {...props}>
    {header}
    <StyledParagraph>{children}</StyledParagraph>
  </CircleWrapper>
);

InfoCircle.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
};

export default InfoCircle;
