import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paragraph from './Paragraph';
import breakpoints from '../../utils/breakpoints';

const Wrapper = styled.div`
  display: inline-block;
  text-align: left;
  max-width: 28rem;

  @media ${breakpoints.toMd} {
    position: relative;
    margin-top: ${({ iconOffset }) =>
      `${iconOffset < 0 ? -1 * iconOffset : 0}px`};
  }

  @media ${breakpoints.lg} {
    max-width: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 1rem;

  @media ${breakpoints.lg} {
    padding-top: 1.5rem;
  }

  @media ${breakpoints.xl} {
    padding-top: 2rem;
  }
`;

const IconWrapper = styled.div`
  background: linear-gradient(to bottom, #ffcd1f, #ed8923);
  border-radius: 50%;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.375rem;
  height: 5.375rem;
  flex: 0 0 5.375rem;
  max-width: 5.375rem;

  @media ${breakpoints.toMd} {
    position: absolute;
    right: 0;
    top: ${({ iconOffset }) => `${iconOffset}px`};
  }

  @media ${breakpoints.md} {
    width: 6rem;
    height: 6rem;
    flex: 0 0 6rem;
    margin-right: 2rem;
    max-width: 6rem;
  }

  @media ${breakpoints.xl} {
    width: 6.625rem;
    height: 6.625rem;
    flex: 0 0 6.625rem;
    margin-right: 3.125rem;
    max-width: 6.625rem;
  }
`;

const StyledParagraph = styled(Paragraph)`
  @media ${breakpoints.toMd} {
    text-align: center;
  }

  margin: 0;
`;

const InfoWithIcon = ({ header, icon, children, iconOffset, ...props }) => (
  <Wrapper {...props} iconOffset={iconOffset || 0}>
    {header}
    <TextWrapper>
      <IconWrapper iconOffset={iconOffset || 0}>{icon}</IconWrapper>
      <StyledParagraph>{children}</StyledParagraph>
    </TextWrapper>
  </Wrapper>
);

InfoWithIcon.propTypes = {
  header: PropTypes.node,
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default InfoWithIcon;
