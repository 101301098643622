import React from 'react';
import styled from 'styled-components';
import InfoCircle from '../atoms/InfoCircle';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  InfoHeaderBlack,
  InfoHeaderLargeBlue,
  infoHeadersTransformations,
} from '../atoms/InfoHeaders';
import breakpoints from '../../utils/breakpoints';

const StyledInfoCircle = styled(InfoCircle)`
  @media ${breakpoints.lg} {
    max-width: 28rem;
    margin-top: 4rem;

    &::before {
      width: 28rem;
      height: 28rem;
    }
  }

  @media ${breakpoints.xxl} {
    max-width: 30rem;
    margin-top: 9rem;

    &::before {
      width: 30rem;
      height: 30rem;
    }
  }
`;

const Header = styled.header`
  & > ${InfoHeaderLargeBlue} {
    padding-left: 2rem;
  }

  & > ${InfoHeaderBlack} {
    display: block;
  }
`;

const Info3 = () => (
  <StyledInfoCircle
    header={
      <Header>
        <FormattedMessage
          id="home.informationSection.items.2.header"
          values={infoHeadersTransformations}
        />
      </Header>
    }
  >
    <FormattedHTMLMessage id="home.informationSection.items.2.text" />
  </StyledInfoCircle>
);

export default Info3;
