import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { cardStyles, deceleratedEasing } from '../../utils/styles';
import { PKOH3 } from '../atoms/Headers';
import colors from '../../utils/colors';
import breakpoints from '../../utils/breakpoints';
import fonts from '../../utils/fonts';
import { transparentize } from 'polished';

const StyledCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 350ms ${deceleratedEasing};
  transform-style: preserve-3d;
`;

const Front = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backface-visibility: hidden;
  ${cardStyles};
  padding: 2rem 1.875rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and ${breakpoints.ie} {
    backface-visibility: visible;
  }
`;

const Back = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  ${cardStyles};
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and ${breakpoints.ie} {
    display: none;
    backface-visibility: visible;
  }
`;

const StyledCard = styled.div`
  max-width: 100%;
  max-width: 32rem;
  height: 21.25rem;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  text-align: left;

  outline: none !important;

  @media ${breakpoints.toLg} {
    ${StyledCardInner} {
      transform: ${({ active }) =>
        active ? 'rotateY(180deg)' : 'rorateY(0deg)'};
    }
  }

  @media ${breakpoints.toLg} and ${breakpoints.ie} {
    ${({ active }) =>
      active &&
      css`
        ${Back} {
          display: flex;
        }

        ${Front} {
          display: none;
        }
      `}
  }

  @media ${breakpoints.lg} {
    &:hover {
      ${StyledCardInner} {
        transform: rotateY(180deg);
      }
    }
  }

  @media ${breakpoints.lg} and ${breakpoints.ie} {
    &:hover {
      ${Back} {
        display: flex;
      }

      ${Front} {
        display: none;
      }
    }
  }
`;

export const styleTextCardShape = c => styled(c)`
  position: absolute;
  top: 2rem;
  max-width: 58%;
  right: 1.875rem;
  z-index: -1;

  @media ${breakpoints.md} {
    top: 2rem;
    bottom: 2.5rem;
    max-width: none;
  }
`;

const Number = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${({ number }) =>
    ['#6BBF28', '#F3387A', '#FFC331', '#21C2A4', '#F8671E'][(number - 1) % 5]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.barlowCondensed};
  font-size: 1.875rem;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
`;

const CardTitle = styled(PKOH3)`
  font-size: 2.5rem;
  color: #065ec9;

  @media ${breakpoints.md} {
    font-size: 3rem;
  }

  @media ${breakpoints.xl} {
    font-size: 3.25rem;
  }

  @media ${breakpoints.desktop} {
    font-size: 3.5rem;
  }
`;

const Hr = styled.hr`
  border: 0;
  height: 1px;
  width: 100%;
  background: linear-gradient(
    to right,
    ${transparentize(0.4, colors.blue)},
    ${transparentize(1, colors.blue)} 70%
  );
`;

const TextCard = ({ title, number, children, shape, ...props }) => {
  const [active, setActive] = useState(false);

  return (
    <StyledCard
      {...props}
      active={active}
      tabIndex={0}
      onClick={() => setActive(!active)}
    >
      <StyledCardInner>
        <Front>
          <Number number={number}>{number}</Number>
          {shape}
          <CardTitle>{title}</CardTitle>
        </Front>
        <Back>
          <Hr />
          {children}
        </Back>
      </StyledCardInner>
    </StyledCard>
  );
};

export default TextCard;
