import styled from 'styled-components';
import colors from '../../utils/colors';
import breakpoints from '../../utils/breakpoints';

const RedShortHr = styled.div`
  width: 3.25rem;
  background-color: ${colors.red};
  border: 0;
  height: 0.5rem;
  margin: 1.875rem 0 1.25rem 0;

  @media ${breakpoints.md} {
    width: 100%;
  }
`;

export default RedShortHr;
