import styled from 'styled-components';
import React from 'react';
import colors from '../../utils/colors';
import Container from '../atoms/Container';
import { SectionHeader } from '../atoms/SectionHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import Section from '../atoms/Section';
import Info1 from '../molecules/Info1';
import Info2 from '../molecules/Info2';
import breakpoints from '../../utils/breakpoints';
import MobileHideOverflow from '../atoms/MobileHideOverflow';
import KeyFinding1Image from '../images/KeyFinding1Image';
import KeyFinding2Image from '../images/KeyFinding2Image';

const StyledSection = styled(Section)`
  background-color: ${colors.grayLighter};
  position: relative;
`;

const StyledContainer = styled(Container)`
  display: grid;
  position: relative;
  grid-template-areas: 'header' 'info1' 'info2';
  padding-top: 4rem;

  @media ${breakpoints.md} {
    padding-top: 0;
    grid-row-gap: 0;
  }

  @media ${breakpoints.lg} {
    justify-content: space-between;
    grid-template-areas: 'header header' 'info1 info2';
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
  }
`;

const StyledSectionHeader = styled(SectionHeader)`
  grid-area: header;

  @media ${breakpoints.lg} {
    margin-bottom: 2rem;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
`;

const StyledKeyFinding1ImageWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  z-index: 10;
  top: -6.5rem;
  right: 0;
  width: 12.5rem;
  height: 12.5rem;

  @media ${breakpoints.md} {
    top: -6vh;
    right: 1rem;
    width: 20rem;
    height: 20rem;
  }

  @media ${breakpoints.lg} {
    top: -10vh;
    right: 2rem;
    width: 28rem;
    height: 28rem;
  }

  @media ${breakpoints.xl} {
    top: -12vh;
    right: 2rem;
    width: 29rem;
    height: 29rem;
  }
`;

const StyledKeyFinding1Image = styled(KeyFinding1Image)`
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  top: 0;
  right: -2.5rem;
  width: 100%;

  @media ${breakpoints.md} {
    right: 0;
  }
`;

const StyledKeyFinding2Wrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 40rem;
  z-index: 10;
  left: 0;
  width: 16rem;
  height: 16rem;

  @media ${breakpoints.md} {
    top: 39rem;
    width: 19rem;
    height: 19rem;
  }

  @media ${breakpoints.lg} {
    display: none;
  }
`;

const StyledKeyFinding2Image = styled(KeyFinding2Image)`
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  top: 0;
  left: -4rem;
  width: 100%;

  @media ${breakpoints.md} {
    left: -3rem;
  }
`;

const StyledInfo1 = styled(Info1)`
  grid-area: info1;
  justify-self: center;
  flex-shrink: 0;

  @media ${breakpoints.lg} {
    justify-self: auto;

    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
  }
`;

const StyledInfo2 = styled(Info2)`
  grid-area: info2;
  justify-self: center;
  margin-top: 18rem;

  @media ${breakpoints.md} {
    margin-top: 8rem;
  }

  @media ${breakpoints.lg} {
    margin-top: 5rem;
    justify-self: auto;
    align-self: center;

    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
  }
`;

const InformationIntroSection = () => (
  <StyledSection
    id={useIntl().formatMessage({ id: 'home.informationSection.id' })}
  >
    <StyledContainer>
      <StyledSectionHeader
        indent
        rocketId="nav-rocket-2"
        subtitle={
          <FormattedMessage id="home.informationSection.header.subtitle" />
        }
      >
        <FormattedMessage id="home.informationSection.header.main" />
      </StyledSectionHeader>

      <StyledKeyFinding1ImageWrapper>
        <StyledKeyFinding1Image />
      </StyledKeyFinding1ImageWrapper>

      <StyledKeyFinding2Wrapper>
        <StyledKeyFinding2Image />
      </StyledKeyFinding2Wrapper>

      <MobileHideOverflow>
        <StyledInfo1 />
      </MobileHideOverflow>
      <StyledInfo2 />
    </StyledContainer>
  </StyledSection>
);

export default InformationIntroSection;
