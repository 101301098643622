import React from 'react';
import messageArrowsIcon from '../../images/message-arrows-icon.svg';

const MessageArrowsIcon = props => {
  return (
    <img src={messageArrowsIcon} alt="MessageArrowsIcon" {...props} />
  );
};

export default MessageArrowsIcon;
