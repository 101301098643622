import React from 'react';
import QuotePictures, {
  Overlay,
  QuotePictureWrapper,
} from '../molecules/QuotePictures';
import { PKOH5 } from '../atoms/Headers';
import Paragraph from '../atoms/Paragraph';
import styled from 'styled-components';
import colors from '../../utils/colors';

const Wrapper = styled.div`
  border: 0;
  background: none;
  text-align: left;
  cursor: pointer;
  color: ${colors.black};

  ${QuotePictureWrapper} .gatsby-image-wrapper {
    filter: grayscale(100%);
  }

  &:hover {
    ${QuotePictureWrapper} .gatsby-image-wrapper {
      filter: none;
    }

    ${Overlay} {
      opacity: 0;
    }
  }
`;

const Author = styled(Paragraph)`
  color: ${colors.blue};
  margin: 0.5rem 0;

  strong {
    font-weight: 600;
  }
`;

const ExpertStudyListItem = ({ title, authors, images, onClick, ...props }) => (
  <Wrapper {...props} onClick={onClick} tabIndex={0}>
    <QuotePictures images={images} vertical action overlay  />
    <PKOH5>{title}</PKOH5>
    <Author>
      <strong>{authors[0].name}</strong>
      <br />
      {authors[0].company}
    </Author>
    {authors[1] && (
      <Author>
        <strong>{authors[1].name}</strong>
        <br />
        {authors[1].company}
      </Author>
    )}
  </Wrapper>
);

export default ExpertStudyListItem;
