import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

const MobileInfoImageWrapper = styled.div`
  position: absolute;
  overflow: hidden;

  @media ${breakpoints.lg} {
    display: none;
  }

  & > .gatsby-image-wrapper {
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    top: 0;
    width: 100%;
  }
`;

export default MobileInfoImageWrapper;
