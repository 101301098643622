import React from 'react';
import tips1 from '../../images/tips-1.svg';

const Tips1Shape = props => {
  return (
    <img src={tips1} alt="Tips1Shape" {...props} />
  );
};

export default Tips1Shape;
