import React, { createElement } from 'react';
import loadable from '@loadable/component';

const components = {
  'Bartosz Sawicki': loadable(() => import('./ExpertBSPhoto')),
  'Radosław Kwiatkowski': loadable(() => import('./ExpertRKPhoto')),
  'Joanna Misiewicz': loadable(() => import('./ExpertJMPhoto')),
  'Przemek Kondraciuk': loadable(() => import('./ExpertPKPhoto')),
  'Michał Potoczek': loadable(() => import('./ExpertMPPhoto')),
  'Michał Kramarz': loadable(() => import('./ExpertMKPhoto')),
  'Piotr Brewiński': loadable(() => import('./ExpertPBPhoto')),
  'Paweł Widawski': loadable(() => import('./ExpertPWPhoto')),
};

const ExpertPhotoProvider = ({ name }) => {
  const component = components[name];

  return component ? createElement(component) : name;
};

export default ExpertPhotoProvider;
