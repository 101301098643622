import React from 'react';
import tips4 from '../../images/tips-4.svg';

const Tips4Shape = props => {
  return (
    <img src={tips4} alt="Tips4Shape" {...props} />
  );
};

export default Tips4Shape;
